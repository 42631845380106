<template>
  <div>
    <table class="min-w-full divide-y divide-slate-900">
        <div class="mt-2 flex flex-col">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle px-4 md:px-6 lg:px-8">
                  <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
                    <table class="min-w-full divide-y divide-slate-900">
                    <thead class="bg-slate-800">
                        <tr>
                          <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-slate-300 sm:pl-6">Nazwa Faktury</th>
                          <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-300">Zafakturowany okres</th>
                          <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-300">Termin płatności</th>
                          <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-300">Data pierwszego pobrania</th>
                          <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-300">Do zapłaty pozostało</th>
                          <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-300">Zapłacono</th>
                          <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-300"></th>
                        </tr>
                    </thead>
                    <tbody class="divide-y divide-slate-700 bg-slate-500">
                        <tr v-for="(invoice, index) in invoicesList" :key="index" :class="[index % 2 ? 'bg-slate-700' : 'bg-slate-600']">
                          <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-slate-200 sm:pl-6">{{ invoice.invoiceName }}</td>
                          <td class="whitespace-nowrap px-2 py-2 text-sm text-slate-200"><span class="flex items-center">{{ invoice.period.slice(0, 10) }} <ArrowNarrowRightIcon class="h-3.5 w-3.5 mx-1 flex-shrink-0" aria-hidden="true" /> {{ invoice.period.slice(13, 24) }}</span></td>
                          <td class="whitespace-nowrap px-2 py-2 text-sm text-slate-300">{{ invoice.paymentDate }}</td>
                          <td class="whitespace-nowrap px-2 py-2 text-sm font-semibold" :class="[invoice.firstDownload ? 'text-green-400' : 'text-slate-300']">{{ invoice.firstDownload || "Brak" }}</td>
                          <td class="whitespace-nowrap px-2 py-2 text-sm  font-semibold" :class="[invoice.paid ? 'text-green-400' : invoice.paidAmount > 0 ? 'text-yellow-400' : 'text-red-400']">{{ invoice.valueOfInvoice - invoice.paidAmount}} PLN</td>
                          <td class="whitespace-nowrap px-2 py-2 text-sm  font-semibold" :class="[invoice.paid ? 'text-green-400' : invoice.paidAmount > 0 ? 'text-yellow-400' : 'text-red-400']">{{ invoice.paid ? 'Tak' : invoice.paidAmount > 0 ? 'Częściowo zapłacona' : 'Nie'}}</td>
                          <td class="whitespace-nowrap px-2 py-2 text-sm text-slate-300 flex"><a @click="downloadInvoice(invoice)"> <DownloadIcon v-if="!invoice.firstDownload" :class="['text-slate-400 hover:text-slate-300', 'mr-3 flex-shrink-0 h-6 w-6', 'transition ease-in-out duration-300 cursor-pointer']" /> <DocumentTextIcon v-if="invoice.firstDownload" :class="['text-slate-400 hover:text-slate-300', 'mr-3 flex-shrink-0 h-6 w-6', 'transition ease-in-out duration-300 cursor-pointer']" /></a><a v-if="invoice.firstDownload" @click="downloadInvoiceOverview(invoice)"> <DocumentReportIcon :class="['text-slate-400 hover:text-slate-300', 'mr-3 flex-shrink-0 h-6 w-6', 'transition ease-in-out duration-300 cursor-pointer']" /></a></td>
                        </tr>
                    </tbody>
                    </table>
                  </div>
                  <div v-if="!invoicesLoading && invoicesList.length === 0">
                      <EmptyState></EmptyState>
                  </div>
                </div>
            </div>
            <div class="flex items-center justify-center mt-4" v-if="queryLimitReached === false">
                <button type="button" @click.prevent="loadMoreUsers" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Załaduj więcej..</button>
            </div>
            <div v-if="invoicesLoading">
              <LoadingSpinnerHub></LoadingSpinnerHub>
            </div> 

        </div>
    </table>
  </div>   
</template>

<script>
import { DownloadIcon, ArrowNarrowRightIcon,DocumentReportIcon, DocumentTextIcon } from '@heroicons/vue/outline'
import { db } from "../firebase/gfbconf"
import LoadingSpinnerHub from '../components/LoadingSpinnerHub.vue';
import EmptyState from '../components/EmptyState.vue';
import moment from 'moment'

export default {
  data() {
    return {
      invoicesList: [],
      invoicesLoading: false,
      dbListener: null,
      queryLoading: false,
      queryLimit: 10,
      queryLimitReached: false
    }
  },
  components: {
    DownloadIcon,
    DocumentTextIcon,
    DocumentReportIcon,
    ArrowNarrowRightIcon,
    LoadingSpinnerHub,
    EmptyState
  },
  methods: {
    async downloadInvoices(){
        const loggedUser = this.$store.state.user.uid
        console.log(loggedUser)
        const query = db.collection("Invoices").where("receiver", "==", loggedUser).where("active", "==", true).orderBy("created.unix", "desc").limit(this.queryLimit);
        this.dbListener = query
        .onSnapshot((querySnapshot) => {
            this.queryLoading = true;

             if(querySnapshot.docs.length<this.queryLimit)
                {
                    this.queryLimitReached = true;
                }
                else
                {
                    this.queryLimitReached = false;
                }

            this.invoicesList = [];

            querySnapshot.forEach((doc) => {
              const data = doc.data()
              data.id = doc.id;

              this.invoicesList.push(data)
            });

            this.queryLoading = false;
        });
    },
    downloadInvoiceOverview(invoice){
      window.open(invoice.overviewPdf.link, "_blank")
    },
    async downloadInvoice(invoice){
      const doc = await db.collection("Invoices").doc(invoice.id);
      window.open(invoice.pdf.link, "_blank")

      if(invoice.firstDownload) return

      moment.locale("pl")
      const firstDownload = moment().format("lll")

      await doc.update({
        firstDownload
      })
    },
    loadMoreUsers()
    {
        this.dbListener();
        this.continueListening();  
    },
    continueListening()
    {
        const loggedUser = this.$store.state.user.uid

        this.queryLimit = this.queryLimit + 10;

        const query = db.collection("Invoices").where("receiver", "==", loggedUser).where("active", "==", true).orderBy("created.unix", "desc").limit(this.queryLimit);

        this.dbListener = query
        .onSnapshot((querySnapshot) => {
            this.queryLoading = true;
            if(querySnapshot.docs.length<this.queryLimit)
            {
                this.queryLimitReached = true;
            }
            else
            {
                this.queryLimitReached = false;
            }
            this.invoicesList = [];

            querySnapshot.forEach((doc) => {
              const data = doc.data()
              data.id = doc.id;

              this.invoicesList.push(data)
            });
            this.queryLoading = false;
        });
    }
    
  },
  async created(){
    await this.downloadInvoices()
  },
  beforeUnmount() {
            if(this.dbListener !== null)
            {
                this.dbListener();
            }  
        }
}
</script>

<style scoped>

</style>