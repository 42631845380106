<template>
  <div>
    <p class="max-w-2xl text-sm text-slate-200">Sprawdź swoje dane.</p>
  </div>
  <div class="mt-8">
    <h3 class="text-lg font-medium leading-6 text-slate-200">Dane podstawowe</h3>
  </div>
  <div class="mt-5 border-slate-200">
    <dl>
      <div class="py-4 px-4 bg-slate-700 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt class="text-sm font-medium text-slate-400">Imię i nazwisko</dt>
        <dd class="mt-1 flex text-sm text-slate-200 sm:col-span-2 sm:mt-0">
          <span class="flex-grow">{{$store.state.userData.name + ' ' + $store.state.userData.surname}}</span>
          <!-- <span class="ml-4 flex-shrink-0">
            <button type="button" class="rounded-md bg-transparent font-medium text-indigo-500 hover:text-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Update</button>
          </span> -->
        </dd>
      </div>
      <div class="py-4 px-4 bg-slate-600 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt class="text-sm font-medium text-slate-400">Numer telefonu</dt>
        <dd class="mt-1 flex text-sm text-slate-200 sm:col-span-2 sm:mt-0">
          <span class="flex-grow">{{$store.state.userData.contact.phone}}</span>
          <!-- <span class="ml-4 flex-shrink-0">
            <button type="button" class="rounded-md bg-transparent font-medium text-indigo-500 hover:text-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Update</button>
          </span> -->
        </dd>
      </div>
      <div class="py-4 px-4 bg-slate-700 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt class="text-sm font-medium text-slate-400">E-mail</dt>
        <dd class="mt-1 flex text-sm text-slate-200 sm:col-span-2 sm:mt-0">
          <span class="flex-grow">{{$store.state.userData.contact.email}}</span>
          <!-- <span class="ml-4 flex-shrink-0">
            <button type="button" class="rounded-md bg-transparent font-medium text-indigo-500 hover:text-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Update</button>
          </span> -->
        </dd>
      </div>
    </dl>
  </div>
  <div class="mt-8">
    <h3 class="text-lg font-medium leading-6 text-slate-200">Dane firmy</h3>
  </div>
  <div class="mt-5">
    <dl>
      <div class="py-4 px-4 bg-slate-700 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt class="text-sm font-medium text-slate-400">Nazwa firmy</dt>
        <dd class="mt-1 flex text-sm text-slate-200 sm:col-span-2 sm:mt-0">
          <span class="flex-grow">{{$store.state.userData.company.name}}</span>
          <!-- <span class="ml-4 flex-shrink-0">
            <button type="button" class="rounded-md bg-transparent font-medium text-indigo-500 hover:text-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Update</button>
          </span> -->
        </dd>
      </div>
      <div class="py-4 px-4 bg-slate-600 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt class="text-sm font-medium text-slate-400">NIP</dt>
        <dd class="mt-1 flex text-sm text-slate-200 sm:col-span-2 sm:mt-0">
          <span class="flex-grow">{{$store.state.userData.company.nip}}</span>
          <!-- <span class="ml-4 flex-shrink-0">
            <button type="button" class="rounded-md bg-transparent font-medium text-indigo-500 hover:text-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Update</button>
          </span> -->
        </dd>
      </div>
      <div class="py-4 px-4 bg-slate-700 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt class="text-sm font-medium text-slate-400">Adres</dt>
        <dd class="mt-1 flex text-sm text-slate-200 sm:col-span-2 sm:mt-0">
          <span class="flex-grow">{{$store.state.userData.company.address}}</span>
          <!-- <span class="ml-4 flex-shrink-0">
            <button type="button" class="rounded-md bg-transparent font-medium text-indigo-500 hover:text-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Update</button>
          </span> -->
        </dd>
      </div>
      <div class="py-4 px-4 bg-slate-600 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt class="text-sm font-medium text-slate-400">Kod pocztowy</dt>
        <dd class="mt-1 flex text-sm text-slate-200 sm:col-span-2 sm:mt-0">
          <span class="flex-grow">{{$store.state.userData.company.postCode}}</span>
          <!-- <span class="ml-4 flex-shrink-0">
            <button type="button" class="rounded-md bg-transparent font-medium text-indigo-500 hover:text-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Update</button>
          </span> -->
        </dd>
      </div>
      <div class="py-4 px-4 bg-slate-700 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt class="text-sm font-medium text-slate-300">Miasto</dt>
        <dd class="mt-1 flex text-sm text-slate-200 sm:col-span-2 sm:mt-0">
          <span class="flex-grow">{{$store.state.userData.company.city}}</span>
          <!-- <span class="ml-4 flex-shrink-0">
            <button type="button" class="rounded-md bg-transparent font-medium text-indigo-500 hover:text-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Update</button>
          </span> -->
        </dd>
      </div>
    </dl>
  </div>
  
  <div class="mt-8">
    <h3 class="text-lg font-medium leading-6 text-slate-200">Karty</h3>
  </div>
  <div class="mt-5">
    <dl>
      <div class="py-4 px-4 bg-slate-700 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt class="text-sm font-medium text-slate-400">Przypisano karty</dt>
        <dd class="mt-1 flex text-sm text-slate-200 sm:col-span-2 sm:mt-0">
          <span class="flex-grow">{{$store.state.userData.groupOfCards.length > 0 ? 'Tak' : 'Nie'}}</span>
          <!-- <span class="ml-4 flex-shrink-0">
            <button type="button" class="rounded-md bg-transparent font-medium text-indigo-500 hover:text-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Update</button>
          </span> -->
        </dd>
      </div>
      <div class="py-4 px-4 bg-slate-600 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt class="text-sm font-medium text-slate-400">Grupa kart przypisana do Twojego konta</dt>
        <dd class="mt-1 flex text-sm text-slate-200 sm:col-span-2 sm:mt-0">
          <span class="flex-grow">{{$store.state.userData.groupOfCards.length > 0 ? $store.state.userData.groupOfCards : '--'}}</span>
          <!-- <span class="ml-4 flex-shrink-0">
            <button type="button" class="rounded-md bg-transparent font-medium text-indigo-500 hover:text-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Update</button>
          </span> -->
        </dd>
      </div>
      <div v-if="$store.state.userData.groupOfCards.length > 0" class="py-4 px-4 bg-slate-700 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt class="text-sm font-medium text-slate-400">Ilość kart</dt>
        <dd class="mt-1 flex text-sm text-slate-200 sm:col-span-2 sm:mt-0">
          <span v-if="!searchingCards" class="flex-grow">{{this.cards}}</span>
          <LoaderData v-if="searchingCards" class="mt-1"></LoaderData>
          <!-- <span class="ml-4 flex-shrink-0">
            <button type="button" class="rounded-md bg-transparent font-medium text-indigo-500 hover:text-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Update</button>
          </span> -->
        </dd>
      </div>
    </dl>
  </div>
  <div class="flex flex-col sm:flex-row text-xs mt-2 text-slate-200 sm:justify-between">
    <div class="flex flex-col sm:flex-row md:flex-col lg:flex-row"><span class="text-slate-400">ID konta:</span><span class="sm:ml-1 md:ml-0 lg:ml-1">{{$store.state.userData.id}}</span></div>
    <div class="flex flex-col sm:flex-row md:flex-col lg:flex-row mt-2 sm:mt-0 sm:ml-2 md:ml-0 lg:ml-2"><span class="text-slate-400">Konto utworzone:</span><span class="sm:ml-1 md:ml-0 lg:ml-1">{{translateDate($store.state.userData.meta.created.unix)}}</span></div>
  </div>
</template>
<script>
import LoaderData from '../components/LoaderData.vue';
import axios from 'axios';
import moment from 'moment';
export default {
  name: "Profile",
  components: {LoaderData},
  data: function () {
    return {
      searchingCards: false,
      cards: 0
    }
  },
  mounted(){
  },
  created(){
    if(this.$store.state.userData.groupOfCards.length > 0){
    this.findCards()
    }
  },
  methods: {
    translateDate(date){
      moment.locale('pl');
      let translatedDate = ""
        translatedDate = moment.unix(date).format('LLLL')
      return translatedDate
    },
    async findCards(){
            this.searchingCards = true
            this.cards = []
            const res = await axios.post("https://europe-central2-lauron-a8b59.cloudfunctions.net/api/getGroupOfCards", {
              group: this.$store.state.userData.groupOfCards
            })
            if(res.data.success)
            {
                if(res.data.cards !== null){
                this.cards = res.data.cards.length
                }else{
                    this.cards = 0
                }
                this.searchingCards = false
            }else{
                this.searchingCards = false
            }
        }
  },
};
</script>
<style scoped></style>
