<template>
  <div>
    <p class="mb-4 truncate text-sm text-slate-200">Wybierz kartę, aby sprawdzić jej szczegółowe dane.</p>
  <div>
        <div v-if="!searchingCards && cards.length > 0" class="grid gap-y-8 lg:gap-x-8 2xl-gap-x-0 w-full grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3">
            <div @click="showDetails(card.PAN, card.CardId)" v-for="(card, index) in cards" :key="index" :class="{'transition duration-500 cursor-pointer rounded-xl relative border border-transparent hover:border-yellow-300 text-white shadow-2xl transform hover:scale-105': true, '2xl:mr-auto': (index+1)%3 === 1 , '2xl:mx-auto': (index+1)%3 === 2, '2xl:ml-auto': (index+1)%3 === 0}">
                <img @load="card.loaded = true" class="relative object-cover w-full h-full rounded-xl" :src="card.imgUrl">
                
                <div v-if="card.loaded" class="w-full px-8 py-8 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <div class="flex justify-between">
                        <div class="">
                            <p class="font-light text-sm sm:text-base lg:text-sm">
                                Użytkownik
                            </p>
                            <p class="font-medium text-sm sm:text-base lg:text-sm tracking-widest">
                                {{$store.state.userData.company.name !== undefined && $store.state.userData.company.name !== null && $store.state.userData.company.name.length > 0 ? $store.state.userData.company.name.length > 20 ? $store.state.userData.company.name.substring(0,20) + '...' : $store.state.userData.company.name : '--'}}
                            </p>
                        </div>
                        <img class="w-10 h-10 sm:w-14 sm:h-14 lg:w-10 lg:h-10 xl:h-14 xl:w-14 2x:h-10 2xl:w-10" src="https://firebasestorage.googleapis.com/v0/b/lauron-a8b59.appspot.com/o/assets%2Fshell-4-logo-svg-vector.svg?alt=media&token=97cdc441-3bfe-4a0a-8296-3d6d3dce0135"/>
                    </div>
                    <div v-if="card.PAN !== null && card.PAN !== undefined" class="pt-2 sm:pt-6 lg:pt-2 xl:pt-6 2xl:pt-2">
                        <p class="font-light text-sm sm:text-base lg:text-sm">
                            Numer karty
                        </p>
                        <p class="font-medium text-sm sm:text-base lg:text-sm tracking-more-wider">
                            {{card.PAN.length > 0 ? addSpaces(card.PAN) : '--'}}
                        </p>
                    </div>
                    <div class="pt-5 sm:pt-10 lg:pt-5 xl:pt-10 2xl:pt-5 pr-6">
                        <div class="flex justify-between">
                            <div v-if="card.VRN !== null || card.DriverName !== null" class="">
                                <p class="font-light text-xs sm:text-sm lg:text-xs">
                                    {{card.VRN !== null && card.VRN.length > 0 ? 'Numer rejestracyjny' : 'Kierowca' }}
                                </p>
                                <p class="font-medium tracking-wider text-xs sm:text-sm lg:text-xs">
                                    {{card.VRN !== null && card.VRN.length > 0 ? card.VRN : card.DriverName }}
                                </p>
                            </div>
                            <div v-if="card.ExpiryDate !== null && card.ExpiryDate !== undefined" class="">
                                <p class="font-light text-xs sm:text-sm lg:text-xs">
                                    Ważna do
                                </p>
                                <p  class="font-medium tracking-wider text-xs sm:text-sm lg:text-xs">
                                    {{card.ExpiryDate[4]+card.ExpiryDate[5]}}/{{card.ExpiryDate[2]+card.ExpiryDate[3]}}
                                </p>
                            </div>
                        </div>
                    </div>
    
                </div>
            </div>
        </div>
        <div v-if="!searchingCards && cards.length === 0">
            <EmptyState></EmptyState>
        </div>
        <div v-if="searchingCards">
            <LoadingSpinnerHub></LoadingSpinnerHub>
        </div>

    </div>
  </div>
</template>

<script>
import LoadingSpinnerHub from '../components/LoadingSpinnerHub.vue';
import EmptyState from '../components/EmptyState.vue';
import axios from 'axios';
export default {
    data()
        {
            return {
                searchingCards: true,
                cards: []
            }
        },
    components: {
        LoadingSpinnerHub,
        EmptyState
    },
    methods: {
        showDetails(PAN,CARDID){
            this.$router.push(`/dashboard/card?id=${PAN}&cid=${CARDID}`)
        },
        addSpaces(number){
            let num = number.toString()
            let joy = num.match(/.{1,4}/g);
            let withSpaces = joy.join(' ')
            return withSpaces
        },
        async findCards(){
            this.searchingCards = true
            this.cards = []
            const res = await axios.post("https://europe-central2-lauron-a8b59.cloudfunctions.net/api/getGroupOfCards", {
              group: this.$store.state.userData.groupOfCards
            })
            if(res.data.success)
            {
                if(res.data.cards !== null){
                    for(let i=0; i< res.data.cards.length; i++)
                    {
                        res.data.cards[i].imgUrl = "https://firebasestorage.googleapis.com/v0/b/lauron-a8b59.appspot.com/o/assets%2F1_fseafaww1-min.jpeg?alt=media&token=37f53c89-0682-4407-9640-c949b44d7fda";
                        res.data.cards[i].loaded = false;
                    }
                this.cards = res.data.cards
                }else{
                    this.cards = []
                }
                this.searchingCards = false
            }else{
                this.searchingCards = false
                alert('Coś poszło nie tak')
            }
        }
    },
    created(){
        this.findCards()
    }
}
</script>

<style scoped>

.text-shadow {
text-shadow: 1px -1px 2px rgba(245, 245, 245, 1);
  }
.text-card {
    font-size: 16px;
    font-size: 150%;
}
</style>