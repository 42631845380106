<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          
          <div  class="relative inline-block align-bottom bg-slate-100 rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-11/12 sm:max-h-screen9/10 sm:p-6">
            <!-- <div v-if="variant.attribs.list.length > 0">{{variant.attribs.list}}</div> -->
                <XIcon @click="closeModal()" class="absolute right-1.5 top-1.5 transition duration-200 ease-in-out h-4 w-4 cursor-pointer text-slate-500 hover:text-slate-700" aria-hidden="true"></XIcon>
                <div class="mb-4">
                    <div class="flex justify-between items-center mb-3">
                    <h3 class="text-base sm:text-lg font-medium leading-6 text-gray-900 mr-4">Dostępność według dni:</h3>
                    <button v-if="!loadingRestrictions && restrictions !== null && !editDays" @click="editDays = !editDays" type="button" class="transition ease-in-out duration-300 inline-flex items-center rounded-md border border-transparent bg-white px-2 py-1.5 text-sm font-medium leading-4 text-slate-500 shadow-sm hover:bg-slate-200 hover:text-slate-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2">
                        <PencilAltIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                        Edytuj
                    </button>
                    <LoaderData v-if="loadingRestrictions" class="mt-2 mb-2"></LoaderData>
                    </div>

                    <dl class="grid grid-cols-1 gap-5 sm:grid-cols-2 2xl:grid-cols-7">
                    <div :class="{'transition-all duration-700 relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6': true, 'pb-12': editDays, 'border border-red-400': whereError.Monday }">
                        <dt>
                        <div class="absolute h-12 w-12 z-10 flex justify-center items-center rounded-md text-white bg-blue-500">
                            PON
                        </div>
                        <p class="ml-16 truncate text-sm font-medium text-gray-500">Poniedziałek</p>
                        </dt>
                        <dd :class="{'ml-16 flex items-baseline': true, 'pb-6 sm:pb-7': editDays, 'pb-5 sm:pb-6': !editDays}">
                        <p class="text-xl font-semibold text-gray-900"><LoaderData v-if="loadingRestrictions" class="mt-2 mb-2"></LoaderData><span v-if="!loadingRestrictions && restrictions !== null">{{restrictions.Restrictions.DayTimeRestrictions.Monday === dataToChange.DayTimeRestrictions.Monday ? restrictions.Restrictions.DayTimeRestrictions.Monday ? 'Tak' : 'Nie' : dataToChange.DayTimeRestrictions.Monday ? 'Tak' : 'Nie'}}</span></p>
                        <Transition name="fade">
                        <div v-if="editDays" class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                            <div class="text-sm flex justify-center">
                            <div @click.prevent="switchDay('Monday')" class="transition duration-300 ease-in-out cursor-pointer font-medium text-blue-700 hover:text-blue-500"><span v-if="!loadingRestrictions && restrictions !== null" :class="[dataToChange.DayTimeRestrictions.Monday ? 'text-red-500 hover:text-red-700' : 'text-green-500 hover:text-green-700']">{{dataToChange.DayTimeRestrictions.Monday ? 'Wyłącz' : 'Włącz'}}</span></div>
                            </div>
                        </div>
                        </Transition>
                        </dd>
                    </div>
                     <div :class="{'transition-all duration-700 relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6': true, 'pb-12': editDays, 'border border-red-400': whereError.Tuesday}">
                        <dt>
                        <div class="absolute h-12 w-12 z-10 flex justify-center items-center rounded-md text-white bg-blue-500">
                            WT
                        </div>
                        <p class="ml-16 truncate text-sm font-medium text-gray-500">Wtorek</p>
                        </dt>
                        <dd :class="{'ml-16 flex items-baseline': true, 'pb-6 sm:pb-7': editDays, 'pb-5 sm:pb-6': !editDays}">
                        <p class="text-xl font-semibold text-gray-900"><LoaderData v-if="loadingRestrictions" class="mt-2 mb-2"></LoaderData><span v-if="!loadingRestrictions && restrictions !== null">{{restrictions.Restrictions.DayTimeRestrictions.Tuesday === dataToChange.DayTimeRestrictions.Tuesday ? restrictions.Restrictions.DayTimeRestrictions.Tuesday ? 'Tak' : 'Nie' : dataToChange.DayTimeRestrictions.Tuesday ? 'Tak' : 'Nie'}}</span></p>
                        <Transition name="fade">
                        <div v-if="editDays" class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                            <div class="text-sm flex justify-center">
                            <div @click.prevent="switchDay('Tuesday')" class="transition duration-300 ease-in-out cursor-pointer font-medium text-blue-700 hover:text-blue-500"><span v-if="!loadingRestrictions && restrictions !== null" :class="[dataToChange.DayTimeRestrictions.Tuesday ? 'text-red-500 hover:text-red-700' : 'text-green-500 hover:text-green-700']">{{dataToChange.DayTimeRestrictions.Tuesday ? 'Wyłącz' : 'Włącz'}}</span></div>
                            </div>
                        </div>
                        </Transition>
                        </dd>
                    </div>
                     <div :class="{'transition-all duration-700 relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6': true, 'pb-12': editDays, 'border border-red-400': whereError.Wednesday}">
                        <dt>
                        <div class="absolute h-12 w-12 z-10 flex justify-center items-center rounded-md text-white bg-blue-500">
                            ŚR
                        </div>
                        <p class="ml-16 truncate text-sm font-medium text-gray-500">Środa</p>
                        </dt>
                        <dd :class="{'ml-16 flex items-baseline': true, 'pb-6 sm:pb-7': editDays, 'pb-5 sm:pb-6': !editDays}">
                        <p class="text-xl font-semibold text-gray-900"><LoaderData v-if="loadingRestrictions" class="mt-2 mb-2"></LoaderData><span v-if="!loadingRestrictions && restrictions !== null">{{restrictions.Restrictions.DayTimeRestrictions.Wednesday === dataToChange.DayTimeRestrictions.Wednesday ? restrictions.Restrictions.DayTimeRestrictions.Wednesday ? 'Tak' : 'Nie' : dataToChange.DayTimeRestrictions.Wednesday ? 'Tak' : 'Nie'}}</span></p>
                        <Transition name="fade">
                        <div v-if="editDays" class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                            <div class="text-sm flex justify-center">
                            <div @click.prevent="switchDay('Wednesday')" class="transition duration-300 ease-in-out cursor-pointer font-medium text-blue-700 hover:text-blue-500"><span v-if="!loadingRestrictions && restrictions !== null" :class="[dataToChange.DayTimeRestrictions.Wednesday ? 'text-red-500 hover:text-red-700' : 'text-green-500 hover:text-green-700']">{{dataToChange.DayTimeRestrictions.Wednesday ? 'Wyłącz' : 'Włącz'}}</span></div>
                            </div>
                        </div>
                        </Transition>
                        </dd>
                    </div>
                     <div :class="{'transition-all duration-700 relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6': true, 'pb-12': editDays, 'border border-red-400': whereError.Thursday}">
                        <dt>
                        <div class="absolute h-12 w-12 z-10 flex justify-center items-center rounded-md text-white bg-blue-500">
                            CZW
                        </div>
                        <p class="ml-16 truncate text-sm font-medium text-gray-500">Czwartek</p>
                        </dt>
                        <dd :class="{'ml-16 flex items-baseline': true, 'pb-6 sm:pb-7': editDays, 'pb-5 sm:pb-6': !editDays}">
                        <p class="text-xl font-semibold text-gray-900"><LoaderData v-if="loadingRestrictions" class="mt-2 mb-2"></LoaderData><span v-if="!loadingRestrictions && restrictions !== null">{{restrictions.Restrictions.DayTimeRestrictions.Thursday === dataToChange.DayTimeRestrictions.Thursday ? restrictions.Restrictions.DayTimeRestrictions.Thursday ? 'Tak' : 'Nie' : dataToChange.DayTimeRestrictions.Thursday ? 'Tak' : 'Nie'}}</span></p>
                        <Transition name="fade">
                        <div v-if="editDays" class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                            <div class="text-sm flex justify-center">
                            <div @click.prevent="switchDay('Thursday')" class="transition duration-300 ease-in-out cursor-pointer font-medium text-blue-700 hover:text-blue-500"><span v-if="!loadingRestrictions && restrictions !== null" :class="[dataToChange.DayTimeRestrictions.Thursday ? 'text-red-500 hover:text-red-700' : 'text-green-500 hover:text-green-700']">{{dataToChange.DayTimeRestrictions.Thursday ? 'Wyłącz' : 'Włącz'}}</span></div>
                            </div>
                        </div>
                        </Transition>
                        </dd>
                    </div>
                     <div :class="{'transition-all duration-700 relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6': true, 'pb-12': editDays, 'border border-red-400': whereError.Friday}">
                        <dt>
                        <div class="absolute h-12 w-12 z-10 flex justify-center items-center rounded-md text-white bg-blue-500">
                            PT
                        </div>
                        <p class="ml-16 truncate text-sm font-medium text-gray-500">Piątek</p>
                        </dt>
                        <dd :class="{'ml-16 flex items-baseline': true, 'pb-6 sm:pb-7': editDays, 'pb-5 sm:pb-6': !editDays}">
                        <p class="text-xl font-semibold text-gray-900"><LoaderData v-if="loadingRestrictions" class="mt-2 mb-2"></LoaderData><span v-if="!loadingRestrictions && restrictions !== null">{{restrictions.Restrictions.DayTimeRestrictions.Friday === dataToChange.DayTimeRestrictions.Friday ? restrictions.Restrictions.DayTimeRestrictions.Friday ? 'Tak' : 'Nie' : dataToChange.DayTimeRestrictions.Friday ? 'Tak' : 'Nie'}}</span></p>
                        <Transition name="fade">
                        <div v-if="editDays" class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                            <div class="text-sm flex justify-center">
                            <div @click.prevent="switchDay('Friday')" class="transition duration-300 ease-in-out cursor-pointer font-medium text-blue-700 hover:text-blue-500"><span v-if="!loadingRestrictions && restrictions !== null" :class="[dataToChange.DayTimeRestrictions.Friday ? 'text-red-500 hover:text-red-700' : 'text-green-500 hover:text-green-700']">{{dataToChange.DayTimeRestrictions.Friday ? 'Wyłącz' : 'Włącz'}}</span></div>
                            </div>
                        </div>
                        </Transition>
                        </dd>
                    </div>
                     <div :class="{'transition-all duration-700 relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6': true, 'pb-12': editDays, 'border border-red-400': whereError.Saturday}">
                        <dt>
                        <div class="absolute h-12 w-12 z-10 flex justify-center items-center rounded-md text-white bg-blue-500">
                            SOB
                        </div>
                        <p class="ml-16 truncate text-sm font-medium text-gray-500">Sobota</p>
                        </dt>
                        <dd :class="{'ml-16 flex items-baseline': true, 'pb-6 sm:pb-7': editDays, 'pb-5 sm:pb-6': !editDays}">
                        <p class="text-xl font-semibold text-gray-900"><LoaderData v-if="loadingRestrictions" class="mt-2 mb-2"></LoaderData><span v-if="!loadingRestrictions && restrictions !== null">{{restrictions.Restrictions.DayTimeRestrictions.Saturday === dataToChange.DayTimeRestrictions.Saturday ? restrictions.Restrictions.DayTimeRestrictions.Saturday ? 'Tak' : 'Nie' : dataToChange.DayTimeRestrictions.Saturday ? 'Tak' : 'Nie'}}</span></p>
                        <Transition name="fade">
                        <div v-if="editDays" class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                            <div class="text-sm flex justify-center">
                            <div @click.prevent="switchDay('Saturday')" class="transition duration-300 ease-in-out cursor-pointer font-medium text-blue-700 hover:text-blue-500"><span v-if="!loadingRestrictions && restrictions !== null" :class="[dataToChange.DayTimeRestrictions.Saturday ? 'text-red-500 hover:text-red-700' : 'text-green-500 hover:text-green-700']">{{dataToChange.DayTimeRestrictions.Saturday ? 'Wyłącz' : 'Włącz'}}</span></div>
                            </div>
                        </div>
                        </Transition>
                        </dd>
                    </div>
                     <div :class="{'transition-all duration-700 relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6': true, 'pb-12': editDays, 'border border-red-400': whereError.Sunday}">
                        <dt>
                        <div class="absolute h-12 w-12 z-10 flex justify-center items-center rounded-md text-white bg-blue-500">
                            ND
                        </div>
                        <p class="ml-16 truncate text-sm font-medium text-gray-500">Niedziela</p>
                        </dt>
                        <dd :class="{'ml-16 flex items-baseline': true, 'pb-6 sm:pb-7': editDays, 'pb-5 sm:pb-6': !editDays}">
                        <p class="text-xl font-semibold text-gray-900"><LoaderData v-if="loadingRestrictions" class="mt-2 mb-2"></LoaderData><span v-if="!loadingRestrictions && restrictions !== null">{{restrictions.Restrictions.DayTimeRestrictions.Sunday === dataToChange.DayTimeRestrictions.Sunday ? restrictions.Restrictions.DayTimeRestrictions.Sunday ? 'Tak' : 'Nie' : dataToChange.DayTimeRestrictions.Sunday ? 'Tak' : 'Nie'}}</span></p>
                        <Transition name="fade">
                        <div v-if="editDays" class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                            <div class="text-sm flex justify-center">
                            <div @click.prevent="switchDay('Sunday')" class="transition duration-300 ease-in-out cursor-pointer font-medium text-blue-700 hover:text-blue-500"><span v-if="!loadingRestrictions && restrictions !== null" :class="[dataToChange.DayTimeRestrictions.Sunday ? 'text-red-500 hover:text-red-700' : 'text-green-500 hover:text-green-700']">{{dataToChange.DayTimeRestrictions.Sunday ? 'Wyłącz' : 'Włącz'}}</span></div>
                            </div>
                        </div>
                        </Transition>
                        </dd>
                    </div>
                    </dl>
                </div>
                <div class="mb-4">
                    <div class="flex justify-between items-center mb-3">
                    <h3 class="text-base sm:text-lg font-medium leading-6 text-gray-900 mr-4">Dostępność według godziny:</h3>
                    <button v-if="!loadingRestrictions && restrictions !== null && !editTime" @click="editTime = !editTime, overflowTimepicker()" type="button" class="transition ease-in-out duration-300 inline-flex items-center rounded-md border border-transparent bg-white px-2 py-1.5 text-sm font-medium leading-4 text-slate-500 shadow-sm hover:bg-slate-200 hover:text-slate-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2">
                        <PencilAltIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                        Edytuj
                    </button>
                    <LoaderData v-if="loadingRestrictions" class="mt-2 mb-2"></LoaderData>
                    </div>

                    <dl class="grid grid-cols-1 gap-5 md:grid-cols-2">
                    <div :class="{'transition-all duration-700 relative rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6': true, 'pb-12': editTime, 'overflow-hidden': timePickerOverflow, 'border border-red-400': whereError.TimeFrom}">
                        <dt>
                        <p class="truncate text-sm font-medium text-gray-500">Dostępna od</p>
                        </dt>
                        <dd :class="{'flex items-baseline': true, 'pb-6 sm:pb-7': editTime, 'pb-5 sm:pb-6': !editTime}">
                        <p class="text-xl font-semibold text-gray-900"><LoaderData v-if="loadingRestrictions" class="mt-2 mb-2"></LoaderData><span v-if="!loadingRestrictions && restrictions !== null">{{restrictions.Restrictions.DayTimeRestrictions.TimeFrom !== null ? restrictions.Restrictions.DayTimeRestrictions.TimeFrom : 'Brak limitu'}}{{dataToChange.TimeFrom}}</span></p>
                        <Transition name="fade">
                        <div v-if="editTime" class="absolute inset-x-0 bottom-0 rounded-md bg-gray-50 px-4 py-4 sm:px-6">
                            <div class="text-sm flex justify-center">
                            <div class="transition duration-300 ease-in-out cursor-pointer font-medium ">
                                <div v-if="!loadingRestrictions && restrictions !== null" class="transition-all duration-1000 flex items-center">
                                    <Transition name="fade">
                                    <div v-if="dataToChange.DayTimeRestrictions.TimeFrom !== null && dataToChange.DayTimeRestrictions.TimeTo !== null" class="relative mt-1 rounded-md shadow-sm">
                                    <div class="transition duration-300 ease-in-out cursor-pointer font-medium text-blue-700 hover:text-blue-500"><vue-timepicker v-if="!loadingRestrictions && restrictions !== null" format="HH:mm:ss" v-model="dataToChange.DayTimeRestrictions.TimeFrom" close-on-complete></vue-timepicker></div>
                                    </div>
                                    </Transition>
                                    <div @click.prevent="switchRestriction('Time')" class="transition duration-300 ease-in-out ml-2 mt-1 cursor-pointer font-medium text-blue-700 hover:text-blue-500"><span v-if="!loadingRestrictions && restrictions !== null" :class="[dataToChange.DayTimeRestrictions.TimeFrom !== null && dataToChange.DayTimeRestrictions.TimeTo !== null ? 'text-red-500 hover:text-red-700' : 'text-green-500 hover:text-green-700']">{{dataToChange.DayTimeRestrictions.TimeFrom !== null && dataToChange.DayTimeRestrictions.TimeTo !== null ? 'Wyłącz' : 'Włącz'}}</span></div>
                                </div>
                            </div>
                            </div>
                            <!-- <div class="text-sm flex justify-center">
                            <div class="transition duration-300 ease-in-out cursor-pointer font-medium text-blue-700 hover:text-blue-500"><vue-timepicker v-if="!loadingRestrictions && restrictions !== null" format="HH:mm:ss" v-model="dataToChange.DayTimeRestrictions.TimeFrom" close-on-complete></vue-timepicker></div>
                            </div> -->
                        </div>
                        </Transition>
                        </dd>
                    </div>
                    <div :class="{'transition-all duration-700 relative rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6': true, 'pb-12': editTime, 'overflow-hidden': timePickerOverflow, 'border border-red-400': whereError.TimeTo}">
                        <dt>
                        <p class="truncate text-sm font-medium text-gray-500">Dostępna do</p>
                        </dt>
                        <dd :class="{'flex items-baseline': true, 'pb-6 sm:pb-7': editTime, 'pb-5 sm:pb-6': !editTime}">
                        <p class="text-xl font-semibold text-gray-900"><LoaderData v-if="loadingRestrictions" class="mt-2 mb-2"></LoaderData><span v-if="!loadingRestrictions && restrictions !== null">{{restrictions.Restrictions.DayTimeRestrictions.TimeTo !== null ? restrictions.Restrictions.DayTimeRestrictions.TimeTo : 'Brak limitu'}}{{dataToChange.TimeTo}}</span></p>
                        <Transition name="fade">
                        <div v-if="editTime" class="absolute inset-x-0 bottom-0 rounded-md bg-gray-50 px-4 py-4 sm:px-6">
                            <div class="text-sm flex justify-center">
                            <div class="transition duration-300 ease-in-out cursor-pointer font-medium ">
                                <div v-if="!loadingRestrictions && restrictions !== null" class="transition-all duration-1000 flex items-center">
                                    <Transition name="fade">
                                    <div v-if="dataToChange.DayTimeRestrictions.TimeFrom !== null && dataToChange.DayTimeRestrictions.TimeTo !== null" class="relative mt-1 rounded-md shadow-sm">
                                    <div class="transition duration-300 ease-in-out cursor-pointer font-medium text-blue-700 hover:text-blue-500"><vue-timepicker v-if="!loadingRestrictions && restrictions !== null" format="HH:mm:ss" v-model="dataToChange.DayTimeRestrictions.TimeTo" close-on-complete></vue-timepicker></div>
                                    </div>
                                    </Transition>
                                    <div @click.prevent="switchRestriction('Time')" class="transition duration-300 ease-in-out ml-2 mt-1 cursor-pointer font-medium text-blue-700 hover:text-blue-500"><span v-if="!loadingRestrictions && restrictions !== null" :class="[dataToChange.DayTimeRestrictions.TimeFrom !== null && dataToChange.DayTimeRestrictions.TimeTo !== null ? 'text-red-500 hover:text-red-700' : 'text-green-500 hover:text-green-700']">{{dataToChange.DayTimeRestrictions.TimeFrom !== null && dataToChange.DayTimeRestrictions.TimeTo !== null ? 'Wyłącz' : 'Włącz'}}</span></div>
                                </div>
                            </div>
                            </div>
                            <!-- <div class="text-sm flex justify-center">
                            <div class="transition duration-300 ease-in-out cursor-pointer font-medium text-blue-700 hover:text-blue-500"><vue-timepicker v-if="!loadingRestrictions && restrictions !== null" format="HH:mm:ss" v-model="dataToChange.DayTimeRestrictions.TimeTo" close-on-complete></vue-timepicker></div>
                            </div> -->
                        </div>
                        </Transition>
                        </dd>
                    </div>
                    </dl>
                </div>
                <div class="mb-4">
                    <div class="flex justify-between items-center mb-3">
                    <h3 class="text-base sm:text-lg font-medium leading-6 text-gray-900 mr-4">Limity wydatków:</h3>
                    <button v-if="!loadingRestrictions && restrictions !== null && !editLimits" @click="editLimits = !editLimits" type="button" class="transition ease-in-out duration-300 inline-flex items-center rounded-md border border-transparent bg-white px-2 py-1.5 text-sm font-medium leading-4 text-slate-500 shadow-sm hover:bg-slate-200 hover:text-slate-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2">
                        <PencilAltIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                        Edytuj
                    </button>
                    <LoaderData v-if="loadingRestrictions" class="mt-2 mb-2"></LoaderData>
                    </div>
                    <dl class="grid grid-cols-1 gap-5 md:grid-cols-3">
                    <div :class="{'transition-all duration-700 relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6': true, 'pb-12': editLimits, 'border border-red-400': whereError.DailySpend}">
                        <dt>
                        <p class="truncate text-sm font-medium text-gray-500">Limit dzienny</p>
                        </dt>
                        <dd :class="{'flex items-baseline': true, 'pb-6 sm:pb-7': editLimits, 'pb-5 sm:pb-6': !editLimits}">
                        <p class="text-xl font-semibold text-gray-900"><LoaderData v-if="loadingRestrictions" class="mt-2 mb-2"></LoaderData><span v-if="!loadingRestrictions && restrictions !== null"><span class="opacity-30">{{restrictions.Restrictions.UsageRestrictions === null || restrictions.Restrictions.UsageRestrictions.DailySpend === null ? '' : restrictions.Restrictions.UsageRestrictions.DailySpendAccumulated.toFixed(2) + ' ' + restrictions.CurrencySymbol + ' / '}}</span>{{restrictions.Restrictions.UsageRestrictions === null || restrictions.Restrictions.UsageRestrictions.DailySpend === null ? 'Brak' : restrictions.Restrictions.UsageRestrictions.DailySpend.toFixed(2) + ' ' + restrictions.CurrencySymbol}}</span></p>
                        <Transition name="fade">
                        <div v-if="editLimits" class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                            <div class="text-sm flex justify-center">
                            <div class="transition duration-300 ease-in-out font-medium hover:text-blue-500">
                                <div v-if="!loadingRestrictions && restrictions !== null" class="transition-all duration-1000 flex items-center">
                                    <div class="relative mt-1 rounded-md shadow-sm">
                                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                        <span class="text-gray-500 text-sm">{{restrictions.CurrencySymbol}}</span>
                                    </div>
                                    <input @change="checkValue('DailySpend')" v-model="dataToChange.UsageRestrictions.DailySpend" type="number" name="price" id="price" class="transition duration-300 ease-in-out block w-full rounded-md border-gray-300 py-1.5 pl-7 pr-12 focus:text-blue-500 focus:border-blue-400 focus:ring-blue-400 sm:text-sm" placeholder="" aria-describedby="price-currency" />
                                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                        <span class="text-gray-500 text-sm" id="price-currency">{{restrictions.CurrencyCode}}</span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </Transition>
                        </dd>
                    </div>

                    <div :class="{'transition-all duration-700 relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6': true, 'pb-12': editLimits, 'border border-red-400': whereError.WeeklySpend}">
                        <dt>
                        <p class="truncate text-sm font-medium text-gray-500">Limit tygodniowy</p>
                        </dt>
                        <dd :class="{'flex items-baseline': true, 'pb-6 sm:pb-7': editLimits, 'pb-5 sm:pb-6': !editLimits}">
                        <p class="text-xl font-semibold text-gray-900"><LoaderData v-if="loadingRestrictions" class="mt-2 mb-2"></LoaderData><span v-if="!loadingRestrictions && restrictions !== null"><span class="opacity-30">{{restrictions.Restrictions.UsageRestrictions === null || restrictions.Restrictions.UsageRestrictions.WeeklySpend === null ? '' : restrictions.Restrictions.UsageRestrictions.WeeklySpendAccumulated.toFixed(2) + ' ' + restrictions.CurrencySymbol + ' / '}}</span>{{restrictions.Restrictions.UsageRestrictions === null || restrictions.Restrictions.UsageRestrictions.WeeklySpend === null ? 'Brak' : restrictions.Restrictions.UsageRestrictions.WeeklySpend.toFixed(2) + ' ' + restrictions.CurrencySymbol}}</span></p>
                        <Transition name="fade">
                        <div v-if="editLimits" class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                            <div class="text-sm flex justify-center">
                            <div class="transition duration-300 ease-in-out font-medium hover:text-blue-500">
                                <div v-if="!loadingRestrictions && restrictions !== null" class="transition-all duration-1000 flex items-center">
                                    <div class="relative mt-1 rounded-md shadow-sm">
                                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                        <span class="text-gray-500 text-sm">{{restrictions.CurrencySymbol}}</span>
                                    </div>
                                    <input @change="checkValue('WeeklySpend')" v-model="dataToChange.UsageRestrictions.WeeklySpend" type="number" name="price" id="price" class="transition duration-300 ease-in-out block w-full rounded-md border-gray-300 py-1.5 pl-7 pr-12 focus:text-blue-500 focus:border-blue-400 focus:ring-blue-400 sm:text-sm" placeholder="" aria-describedby="price-currency" />
                                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                        <span class="text-gray-500 text-sm" id="price-currency">{{restrictions.CurrencyCode}}</span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </Transition>
                        </dd>
                    </div>

                    <div :class="{'transition-all duration-700 relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6': true, 'pb-12': editLimits, 'border border-red-400': whereError.MonthlySpend}">
                        <dt>
                        <p class="truncate text-sm font-medium text-gray-500">Limit miesięczny</p>
                        </dt>
                        <dd :class="{'flex items-baseline': true, 'pb-6 sm:pb-7': editLimits, 'pb-5 sm:pb-6': !editLimits}">
                        <p class="text-xl font-semibold text-gray-900"><LoaderData v-if="loadingRestrictions" class="mt-2 mb-2"></LoaderData><span v-if="!loadingRestrictions && restrictions !== null"><span class="opacity-30">{{restrictions.Restrictions.UsageRestrictions === null || restrictions.Restrictions.UsageRestrictions.MonthlySpend === null ? '' : restrictions.Restrictions.UsageRestrictions.MonthlySpendAccumulated.toFixed(2) + ' ' + restrictions.CurrencySymbol + ' / '}}</span>{{restrictions.Restrictions.UsageRestrictions === null || restrictions.Restrictions.UsageRestrictions.MonthlySpend === null ? 'Brak' : restrictions.Restrictions.UsageRestrictions.MonthlySpend.toFixed(2) + ' ' + restrictions.CurrencySymbol}}</span></p>
                        <Transition name="fade">
                        <div v-if="editLimits" class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                            <div class="text-sm flex justify-center">
                            <div class="transition duration-300 ease-in-out font-medium hover:text-blue-500">
                                <div v-if="!loadingRestrictions && restrictions !== null" class="transition-all duration-1000 flex items-center">
                                    <div class="relative mt-1 rounded-md shadow-sm">
                                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                        <span class="text-gray-500 text-sm">{{restrictions.CurrencySymbol}}</span>
                                    </div>
                                    <input @change="checkValue('MonthlySpend')" v-model="dataToChange.UsageRestrictions.MonthlySpend" type="number" name="price" id="price" class="transition duration-300 ease-in-out block w-full rounded-md border-gray-300 py-1.5 pl-7 pr-12 focus:text-blue-500 focus:border-blue-400 focus:ring-blue-400 sm:text-sm" placeholder="" aria-describedby="price-currency" />
                                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                        <span class="text-gray-500 text-sm" id="price-currency">{{restrictions.CurrencyCode}}</span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </Transition>
                        </dd>
                    </div>
                    </dl>
                    <dl class="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                    <div :class="{'transition-all duration-700 relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6': true, 'pb-12': editLimits, 'border border-red-400': whereError.AnnualSpend}">
                        <dt>
                        <p class="truncate text-sm font-medium text-gray-500">Limit roczny</p>
                        </dt>
                        <dd :class="{'flex items-baseline': true, 'pb-6 sm:pb-7': editLimits, 'pb-5 sm:pb-6': !editLimits}">
                        <p class="text-xl font-semibold text-gray-900"><LoaderData v-if="loadingRestrictions" class="mt-2 mb-2"></LoaderData><span v-if="!loadingRestrictions && restrictions !== null"><span class="opacity-30">{{restrictions.Restrictions.UsageRestrictions === null || restrictions.Restrictions.UsageRestrictions.AnnualSpend === null ? '' : restrictions.Restrictions.UsageRestrictions.AnnualSpendAccumulated.toFixed(2) + ' ' + restrictions.CurrencySymbol + ' / '}}</span>{{restrictions.Restrictions.UsageRestrictions === null || restrictions.Restrictions.UsageRestrictions.AnnualSpend === null ? 'Brak' : restrictions.Restrictions.UsageRestrictions.AnnualSpend.toFixed(2) + ' ' + restrictions.CurrencySymbol}}</span></p>
                        <Transition name="fade">
                        <div v-if="editLimits" class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                            <div class="text-sm flex justify-center">
                            <div class="transition duration-300 ease-in-out font-medium hover:text-blue-500">
                                <div v-if="!loadingRestrictions && restrictions !== null" class="transition-all duration-1000 flex items-center">
                                    <div class="relative mt-1 rounded-md shadow-sm">
                                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                        <span class="text-gray-500 text-sm">{{restrictions.CurrencySymbol}}</span>
                                    </div>
                                    <input @change="checkValue('AnnualSpend')" v-model="dataToChange.UsageRestrictions.AnnualSpend" type="number" name="price" id="price" class="transition duration-300 ease-in-out block w-full rounded-md border-gray-300 py-1.5 pl-7 pr-12 focus:text-blue-500 focus:border-blue-400 focus:ring-blue-400 sm:text-sm" placeholder="" aria-describedby="price-currency" />
                                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                        <span class="text-gray-500 text-sm" id="price-currency">{{restrictions.CurrencyCode}}</span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </Transition>
                        </dd>
                    </div>

                    <div :class="{'transition-all duration-700 relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6': true, 'pb-12': editLimits, 'border border-red-400': whereError.LifeTimeSpend}">
                        <dt>
                        <p class="truncate text-sm font-medium text-gray-500">Limit żywotności karty</p>
                        </dt>
                        <dd :class="{'flex items-baseline': true, 'pb-6 sm:pb-7': editLimits, 'pb-5 sm:pb-6': !editLimits}">
                        <p class="text-xl font-semibold text-gray-900"><LoaderData v-if="loadingRestrictions" class="mt-2 mb-2"></LoaderData><span v-if="!loadingRestrictions && restrictions !== null"><span class="opacity-30">{{restrictions.Restrictions.UsageRestrictions === null || restrictions.Restrictions.UsageRestrictions.LifeTimeSpend === null ? '' : restrictions.Restrictions.UsageRestrictions.LifeTimeSpendAccumulated.toFixed(2) + ' ' + restrictions.CurrencySymbol + ' / '}}</span>{{restrictions.Restrictions.UsageRestrictions === null || restrictions.Restrictions.UsageRestrictions.LifeTimeSpend === null ? 'Brak' : restrictions.Restrictions.UsageRestrictions.LifeTimeSpend.toFixed(2) + ' ' + restrictions.CurrencySymbol}}</span></p>
                        <Transition name="fade">
                        <div v-if="editLimits" class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                            <div class="text-sm flex justify-center">
                            <div class="transition duration-300 ease-in-out font-medium hover:text-blue-500">
                                <div v-if="!loadingRestrictions && restrictions !== null" class="transition-all duration-1000 flex items-center">
                                    <Transition name="fade">
                                    <div v-if="dataToChange.UsageRestrictions.LifeTimeSpend !== null" class="relative mt-1 rounded-md shadow-sm">
                                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                        <span class="text-gray-500 text-sm">{{restrictions.CurrencySymbol}}</span>
                                    </div>
                                    <input @change="checkValue('LifeTimeSpend')" v-model="dataToChange.UsageRestrictions.LifeTimeSpend" type="number" name="price" id="price" class="transition duration-300 ease-in-out block w-full rounded-md border-gray-300 py-1.5 pl-7 pr-12 focus:text-blue-500 focus:border-blue-400 focus:ring-blue-400 sm:text-sm" placeholder="" aria-describedby="price-currency" />
                                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                        <span class="text-gray-500 text-sm" id="price-currency">{{restrictions.CurrencyCode}}</span>
                                    </div>
                                    </div>
                                    </Transition>
                                    <div @click.prevent="switchRestriction('LifeTimeSpend')" class="transition duration-300 ease-in-out ml-2 mt-1 cursor-pointer font-medium text-blue-700 hover:text-blue-500"><span v-if="!loadingRestrictions && restrictions !== null" :class="[dataToChange.UsageRestrictions.LifeTimeSpend !== null ? 'text-red-500 hover:text-red-700' : 'text-green-500 hover:text-green-700']">{{dataToChange.UsageRestrictions.LifeTimeSpend !== null ? 'Wyłącz' : 'Włącz'}}</span></div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </Transition>
                        </dd>
                    </div>
                    </dl>
                </div>
                <div class="mb-4">
                    <div class="flex justify-between items-center mb-3">
                    <h3 class="text-base sm:text-lg font-medium leading-6 text-gray-900 mr-4">Limity transakcji:</h3>
                    <button v-if="!loadingRestrictions && restrictions !== null && !editTransactionLimits" @click="editTransactionLimits = !editTransactionLimits" type="button" class="transition ease-in-out duration-300 inline-flex items-center rounded-md border border-transparent bg-white px-2 py-1.5 text-sm font-medium leading-4 text-slate-500 shadow-sm hover:bg-slate-200 hover:text-slate-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2">
                        <PencilAltIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                        Edytuj
                    </button>
                    <LoaderData v-if="loadingRestrictions" class="mt-2 mb-2"></LoaderData>
                    </div>
                    <dl class="grid grid-cols-1 gap-5 md:grid-cols-3">
                    <div :class="{'transition-all duration-700 relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6': true, 'pb-12': editTransactionLimits, 'border border-red-400': whereError.DailyTransactionCount}">
                        <dt>
                        <p class="truncate text-sm font-medium text-gray-500">Limit dzienny</p>
                        </dt>
                        <dd :class="{'flex items-baseline': true, 'pb-6 sm:pb-7': editTransactionLimits, 'pb-5 sm:pb-6': !editTransactionLimits}">
                        <p class="text-xl font-semibold text-gray-900"><LoaderData v-if="loadingRestrictions" class="mt-2 mb-2"></LoaderData><span v-if="!loadingRestrictions && restrictions !== null"><span class="opacity-30">{{restrictions.Restrictions.UsageRestrictions === null || restrictions.Restrictions.UsageRestrictions.DailyTransactionCount === 0 || restrictions.Restrictions.UsageRestrictions.DailyTransactionCount === null ? '' : restrictions.Restrictions.UsageRestrictions.DailyTransactionAccumulated + ' / '}}</span>{{restrictions.Restrictions.UsageRestrictions === null || restrictions.Restrictions.UsageRestrictions.DailyTransactionCount === 0 || restrictions.Restrictions.UsageRestrictions.DailyTransactionCount === null ? 'Brak' : restrictions.Restrictions.UsageRestrictions.DailyTransactionCount}}</span></p>
                        <Transition name="fade">
                        <div v-if="editTransactionLimits" class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                            <div class="text-sm flex justify-center">
                            <div class="transition duration-300 ease-in-out cursor-pointer font-medium ">
                                <div v-if="!loadingRestrictions && restrictions !== null" class="transition-all duration-1000 flex items-center">
                                    <Transition name="fade">
                                    <div class="relative mt-1 rounded-md shadow-sm">
                                    <input @change="checkValue('DailyTransactionCount')" v-model="dataToChange.UsageRestrictions.DailyTransactionCount" type="number" name="price" id="price" class="transition duration-300 ease-in-out block w-full rounded-md border-gray-300 py-1.5 px-2 focus:text-blue-500 focus:border-blue-400 focus:ring-blue-400 sm:text-sm" placeholder="" aria-describedby="price-currency" />
                                    </div>
                                    </Transition>
                                </div>
                            </div>
                            </div>
                        </div>
                        </Transition>
                        </dd>
                    </div>

                    <div :class="{'transition-all duration-700 relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6': true, 'pb-12': editTransactionLimits, 'border border-red-400': whereError.WeeklyTransactionCount}">
                        <dt>
                        <p class="truncate text-sm font-medium text-gray-500">Limit tygodniowy</p>
                        </dt>
                        <dd :class="{'flex items-baseline': true, 'pb-6 sm:pb-7': editTransactionLimits, 'pb-5 sm:pb-6': !editTransactionLimits}">
                        <p class="text-xl font-semibold text-gray-900"><LoaderData v-if="loadingRestrictions" class="mt-2 mb-2"></LoaderData><span v-if="!loadingRestrictions && restrictions !== null"><span class="opacity-30">{{restrictions.Restrictions.UsageRestrictions === null || restrictions.Restrictions.UsageRestrictions.WeeklyTransactionCount === 0 || restrictions.Restrictions.UsageRestrictions.WeeklyTransactionCount === null ? '' : restrictions.Restrictions.UsageRestrictions.WeeklyTransactionAccumulated + ' / '}}</span>{{restrictions.Restrictions.UsageRestrictions === null || restrictions.Restrictions.UsageRestrictions.WeeklyTransactionCount === 0 || restrictions.Restrictions.UsageRestrictions.WeeklyTransactionCount === null ? 'Brak' : restrictions.Restrictions.UsageRestrictions.WeeklyTransactionCount}}</span></p>
                        <Transition name="fade">
                        <div v-if="editTransactionLimits" class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                            <div class="text-sm flex justify-center">
                            <div class="transition duration-300 ease-in-out cursor-pointer font-medium">
                                <div v-if="!loadingRestrictions && restrictions !== null" class="transition-all duration-1000 flex items-center">
                                    <Transition name="fade">
                                    <div class="relative mt-1 rounded-md shadow-sm">
                                    <input @change="checkValue('WeeklyTransactionCount')" v-model="dataToChange.UsageRestrictions.WeeklyTransactionCount" type="number" name="price" id="price" class="transition duration-300 ease-in-out block w-full rounded-md border-gray-300 py-1.5 px-2 focus:text-blue-500 focus:border-blue-400 focus:ring-blue-400 sm:text-sm" placeholder="" aria-describedby="price-currency" />
                                    </div>
                                    </Transition>
                                </div>
                            </div>
                            </div>
                        </div>
                        </Transition>
                        </dd>
                    </div>

                    <div :class="{'transition-all duration-700 relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6': true, 'pb-12': editTransactionLimits, 'border border-red-400': whereError.MonthlyTransactionCount}">
                        <dt>
                        <p class="truncate text-sm font-medium text-gray-500">Limit miesięczny</p>
                        </dt>
                        <dd :class="{'flex items-baseline': true, 'pb-6 sm:pb-7': editTransactionLimits, 'pb-5 sm:pb-6': !editTransactionLimits}">
                        <p class="text-xl font-semibold text-gray-900"><LoaderData v-if="loadingRestrictions" class="mt-2 mb-2"></LoaderData><span v-if="!loadingRestrictions && restrictions !== null"><span class="opacity-30">{{restrictions.Restrictions.UsageRestrictions === null || restrictions.Restrictions.UsageRestrictions.MonthlyTransactionCount === 0 || restrictions.Restrictions.UsageRestrictions.MonthlyTransactionCount === null ? '' : restrictions.Restrictions.UsageRestrictions.MonthlyTransactionAccumulated + ' / '}}</span>{{restrictions.Restrictions.UsageRestrictions === null || restrictions.Restrictions.UsageRestrictions.MonthlyTransactionCount === 0 || restrictions.Restrictions.UsageRestrictions.MonthlyTransactionCount === null ? 'Brak' : restrictions.Restrictions.UsageRestrictions.MonthlyTransactionCount}}</span></p>
                        <Transition name="fade">
                        <div v-if="editTransactionLimits" class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                            <div class="text-sm flex justify-center">
                            <div class="transition duration-300 ease-in-out cursor-pointer font-medium ">
                                <div v-if="!loadingRestrictions && restrictions !== null" class="transition-all duration-1000 flex items-center">
                                    <Transition name="fade">
                                    <div class="relative mt-1 rounded-md shadow-sm">
                                    <input @change="checkValue('MonthlyTransactionCount')" v-model="dataToChange.UsageRestrictions.MonthlyTransactionCount" type="number" name="price" id="price" class="transition duration-300 ease-in-out block w-full rounded-md border-gray-300 py-1.5 px-2 focus:text-blue-500 focus:border-blue-400 focus:ring-blue-400 sm:text-sm" placeholder="" aria-describedby="price-currency" />
                                    </div>
                                    </Transition>
                                </div>
                            </div>
                            </div>
                        </div>
                        </Transition>
                        </dd>
                    </div>
                    </dl>
                    <dl class="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                    <div :class="{'transition-all duration-700 relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6': true, 'pb-12': editTransactionLimits, 'border border-red-400': whereError.AnnualTransactionCount}">
                        <dt>
                        <p class="truncate text-sm font-medium text-gray-500">Limit roczny</p>
                        </dt>
                        <dd :class="{'flex items-baseline': true, 'pb-6 sm:pb-7': editTransactionLimits, 'pb-5 sm:pb-6': !editTransactionLimits}">
                        <p class="text-xl font-semibold text-gray-900"><LoaderData v-if="loadingRestrictions" class="mt-2 mb-2"></LoaderData><span v-if="!loadingRestrictions && restrictions !== null"><span class="opacity-30">{{restrictions.Restrictions.UsageRestrictions === null || restrictions.Restrictions.UsageRestrictions.AnnualTransactionCount === 0 || restrictions.Restrictions.UsageRestrictions.AnnualTransactionCount === null ? '' : restrictions.Restrictions.UsageRestrictions.AnnualTransactionAccumulated + ' / '}}</span>{{restrictions.Restrictions.UsageRestrictions === null || restrictions.Restrictions.UsageRestrictions.AnnualTransactionCount === 0 || restrictions.Restrictions.UsageRestrictions.AnnualTransactionCount === null ? 'Brak' : restrictions.Restrictions.UsageRestrictions.AnnualTransactionCount}}</span></p>
                        <Transition name="fade">
                        <div v-if="editTransactionLimits" class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                            <div class="text-sm flex justify-center">
                            <div class="transition duration-300 ease-in-out cursor-pointer font-medium ">
                                <div v-if="!loadingRestrictions && restrictions !== null" class="transition-all duration-1000 flex items-center">
                                    <Transition name="fade">
                                    <div class="relative mt-1 rounded-md shadow-sm">
                                    <input @change="checkValue('AnnualTransactionCount')" v-model="dataToChange.UsageRestrictions.AnnualTransactionCount" type="number" name="price" id="price" class="transition duration-300 ease-in-out block w-full rounded-md border-gray-300 py-1.5 px-2 focus:text-blue-500 focus:border-blue-400 focus:ring-blue-400 sm:text-sm" placeholder="" aria-describedby="price-currency" />
                                    </div>
                                    </Transition>
                                </div>
                            </div>
                            </div>
                        </div>
                        </Transition>
                        </dd>
                    </div>

                    <div :class="{'transition-all duration-700 relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6': true, 'pb-12': editTransactionLimits, 'border border-red-400': whereError.LifeTimeTransactionCount}">
                        <dt>
                        <p class="truncate text-sm font-medium text-gray-500">Limit żywotności karty</p>
                        </dt>
                        <dd :class="{'flex items-baseline': true, 'pb-6 sm:pb-7': editTransactionLimits, 'pb-5 sm:pb-6': !editTransactionLimits}">
                        <p class="text-xl font-semibold text-gray-900"><LoaderData v-if="loadingRestrictions" class="mt-2 mb-2"></LoaderData><span v-if="!loadingRestrictions && restrictions !== null"><span class="opacity-30">{{restrictions.Restrictions.UsageRestrictions === null || restrictions.Restrictions.UsageRestrictions.LifeTimeTransactionCount === 0 || restrictions.Restrictions.UsageRestrictions.LifeTimeTransactionCount === null ? '' : restrictions.Restrictions.UsageRestrictions.LifeTimeTransactionAccumulated + ' / '}}</span>{{restrictions.Restrictions.UsageRestrictions === null || restrictions.Restrictions.UsageRestrictions.LifeTimeTransactionCount === 0 || restrictions.Restrictions.UsageRestrictions.LifeTimeTransactionCount === null ? 'Brak' : restrictions.Restrictions.UsageRestrictions.LifeTimeTransactionCount}}</span></p>
                        <Transition name="fade">
                        <div v-if="editTransactionLimits" class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                            <div class="text-sm flex justify-center">
                            <div class="transition duration-300 ease-in-out cursor-pointer font-medium ">
                                <div v-if="!loadingRestrictions && restrictions !== null" class="transition-all duration-1000 flex items-center">
                                    <Transition name="fade">
                                    <div v-if="dataToChange.UsageRestrictions.LifeTimeTransactionCount !== null" class="relative mt-1 rounded-md shadow-sm">
                                    <input @change="checkValue('LifeTimeTransactionCount')" v-model="dataToChange.UsageRestrictions.LifeTimeTransactionCount" type="number" name="price" id="price" class="transition duration-300 ease-in-out block w-full rounded-md border-gray-300 py-1.5 px-2 focus:text-blue-500 focus:border-blue-400 focus:ring-blue-400 sm:text-sm" placeholder="" aria-describedby="price-currency" />
                                    </div>
                                    </Transition>
                                    <div @click.prevent="switchRestriction('LifeTimeTransactionCount')" class="transition duration-300 ease-in-out ml-2 mt-1 cursor-pointer font-medium text-blue-700 hover:text-blue-500"><span v-if="!loadingRestrictions && restrictions !== null" :class="[dataToChange.UsageRestrictions.LifeTimeTransactionCount !== null ? 'text-red-500 hover:text-red-700' : 'text-green-500 hover:text-green-700']">{{dataToChange.UsageRestrictions.LifeTimeTransactionCount !== null ? 'Wyłącz' : 'Włącz'}}</span></div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </Transition>
                        </dd>
                    </div>
                    </dl>
                    <div v-if="errors.length > 0" class="flex justify-end mt-4">
                        <div class="flex flex-col">
                            <div v-for="error in errors" :key="error" class="text-sm text-red-500">{{error}}</div>
                        </div>
                    </div>
                    <div class="flex justify-end mt-4">
                        <button @click.prevent="closeModal()" type="button" class="transition ease-in-out duration-300 mr-2 inline-flex items-center rounded-md border border-transparent bg-red-500 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">Anuluj</button>
                        <button @click.prevent="checkForErrors()" type="button" class="transition ease-in-out duration-300 inline-flex items-center rounded-md border border-transparent bg-blue-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">Aktualizuj restrykcje</button>
                    </div>
                </div>
                <!-- <button @click.prevent="getRestrictions()" class="bg-black p-1 text-white">TEST API</button> -->
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
  <askFor v-if="askForUpdateRestrictions" @accept="updateRestrictions" :modalData="modalUpdateRestrictions" @closeModal="askForUpdateRestrictions = false"></askFor>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XIcon, PencilAltIcon } from '@heroicons/vue/outline'
import EmptyState from '../components/EmptyState.vue';
import axios from 'axios';
import VueTimepicker from 'vue3-timepicker'
import 'vue3-timepicker/dist/VueTimepicker.css'
import LoaderData from '../components/LoaderData.vue';
import moment from 'moment';
import askFor from '@/components/AlertModal.vue';
export default {
    data()
        {
        return {
            dataToChange:{
                DayTimeRestrictions:{
                    Monday: true,
                    Tuesday: true,
                    Wednesday: true,
                    Thursday: true,
                    Friday: true,
                    Saturday: true,
                    Sunday: true,
                    TimeFrom: "07:00:00",
                    TimeTo: "15:00:00"
                },
                UsageRestrictions: {
                    DailySpend: 1000,
                    WeeklySpend: 7000,
                    MonthlySpend: 30000,
                    AnnualSpend: 365000,
                    LifeTimeSpend: 1000000,
                    DailyTransactionCount: 1,
                    WeeklyTransactionCount: 9,
                    MonthlyTransactionCount: 30,
                    AnnualTransactionCount: 365,
                    LifeTimeTransactionCount: 365
                }
            },
            askForUpdateRestrictions: false,
            modalUpdateRestrictions: {
                headerText: "Aktualizacja restrykcji",
                bodyText: "Czy na pewno chcesz zaktualizować restrykcje karty?",
                acceptButtonText: "Akceptuje"
            },
            whereError: {
                Monday: false,
                Tuesday: false,
                Wednesday: false,
                Thursday: false,
                Friday: false,
                Saturday: false,
                Sunday: false,
                TimeFrom: false,
                TimeTo: false,
                DailySpend: false,
                WeeklySpend: false,
                MonthlySpend: false,
                AnnualSpend: false,
                LifeTimeSpend: false,
                DailyTransactionCount: false,
                WeeklyTransactionCount: false,
                MonthlyTransactionCount: false,
                AnnualTransactionCount: false,
                LifeTimeTransactionCount: false
            },
            errors:[],
            loadingRestrictions: false,
            restrictions: null,
            editDays: false,
            editTime: false,
            editLimits: false,
            editTransactionLimits: false,
            timePickerOverflow: true,
        }
    },
  components: {
    Dialog, DialogOverlay, TransitionChild, TransitionRoot, EmptyState, XIcon, LoaderData, PencilAltIcon, VueTimepicker, askFor
  },
  props: [],
  methods: {
    async updateRestrictions(){
        this.loadingRestrictions = true
        this.restrictions = null
        this.editDays = false,
        this.editTime = false,
        this.editLimits = false,
        this.editTransactionLimits = false
        const res = await axios.post("https://europe-central2-lauron-a8b59.cloudfunctions.net/api/updateRestrictions", {
            cardId: `${this.$route.query.cid}`,
            dataToChange: this.dataToChange
        })
        if(res.data.success){
            if(res.data.log === "Not all updated"){
                alert('Nie wszystkie dane udało się zaktualizować')
            }
            this.getRestrictions()
        }else{
            alert('Nie udało się zaktualizować restrykcji')
            this.getRestrictions()
        }
    },
    async checkForErrors(){
        this.errors = []
        this.whereError = {
            Monday: false,
            Tuesday: false,
            Wednesday: false,
            Thursday: false,
            Friday: false,
            Saturday: false,
            Sunday: false,
            TimeFrom: false,
            TimeTo: false,
            DailySpend: false,
            WeeklySpend: false,
            MonthlySpend: false,
            AnnualSpend: false,
            LifeTimeSpend: false,
            DailyTransactionCount: false,
            WeeklyTransactionCount: false,
            MonthlyTransactionCount: false,
            AnnualTransactionCount: false,
            LifeTimeTransactionCount: false
        }
        let workingTimeCardErrors = await this.checkWorkingTimeCard()
        if(workingTimeCardErrors === 0 && (this.dataToChange.DayTimeRestrictions.TimeFrom !== null && this.dataToChange.DayTimeRestrictions.TimeTo !== null)){
            let timeFromUnix = moment("20230103" + ' ' + this.dataToChange.DayTimeRestrictions.TimeFrom, "YYYYMMDD HH:mm:ss").unix()
            let timeToUnix = moment("20230103" + ' ' + this.dataToChange.DayTimeRestrictions.TimeTo, "YYYYMMDD HH:mm:ss").unix()
            if(timeToUnix - timeFromUnix < 3600){
                this.whereError.TimeTo = true
                this.whereError.TimeFrom = true
                this.errors.push("Dostępność według godziny - minimalne okno czasowe to 1 godzina")
            }
        }
        if(this.dataToChange.UsageRestrictions.DailySpend >= this.dataToChange.UsageRestrictions.WeeklySpend){
            this.whereError.DailySpend = true
            this.whereError.WeeklySpend = true
            this.errors.push("Limity wydatków - limit dzienny musi być mniejszy od limitu tygodniowego")
        }
        if(this.dataToChange.UsageRestrictions.WeeklySpend >= this.dataToChange.UsageRestrictions.MonthlySpend){
            this.whereError.MonthlySpend = true
            this.whereError.WeeklySpend = true
            this.errors.push("Limity wydatków - limit tygodniowy musi być mniejszy od limitu miesięcznego")
        }
        if(this.dataToChange.UsageRestrictions.MonthlySpend >= this.dataToChange.UsageRestrictions.AnnualSpend){
            this.whereError.MonthlySpend = true
            this.whereError.AnnualSpend = true
            this.errors.push("Limity wydatków - limit miesięczny musi być mniejszy od limitu rocznego")
        }

        if(this.dataToChange.UsageRestrictions.DailyTransactionCount >= this.dataToChange.UsageRestrictions.WeeklyTransactionCount){
            this.whereError.DailyTransactionCount = true
            this.whereError.WeeklyTransactionCount = true
            this.errors.push("Limity transakcji - limit dzienny musi być mniejszy od limitu tygodniowego")
        }
        if(this.dataToChange.UsageRestrictions.WeeklyTransactionCount >= this.dataToChange.UsageRestrictions.MonthlyTransactionCount){
            this.whereError.MonthlyTransactionCount = true
            this.whereError.WeeklyTransactionCount = true
            this.errors.push("Limity transakcji - limit tygodniowy musi być mniejszy od limitu miesięcznego")
        }
        if(this.dataToChange.UsageRestrictions.MonthlyTransactionCount >= this.dataToChange.UsageRestrictions.AnnualTransactionCount){
            this.whereError.MonthlyTransactionCount = true
            this.whereError.AnnualTransactionCount = true
            this.errors.push("Limity transakcji - limit miesięczny musi być mniejszy od limitu rocznego")
        }
        if(this.dataToChange.DayTimeRestrictions.Monday === false && this.dataToChange.DayTimeRestrictions.Tuesday === false && this.dataToChange.DayTimeRestrictions.Wednesday === false && this.dataToChange.DayTimeRestrictions.Thursday === false && this.dataToChange.DayTimeRestrictions.Friday === false && this.dataToChange.DayTimeRestrictions.Saturday === false && this.dataToChange.DayTimeRestrictions.Sunday === false){
            this.whereError.Monday = true
            this.whereError.Tuesday = true
            this.whereError.Wednesday = true
            this.whereError.Thursday = true
            this.whereError.Friday = true
            this.whereError.Saturday = true
            this.whereError.Sunday = true
            this.errors.push("Dostępność według dni - karta musi działać conajmniej w jeden dzień")
        }


        // if(this.restrictions.Restrictions.UsageRestrictions !== null){
        //     this.dataToChange.UsageRestrictions = {
        //         DailyTransactionCount: this.restrictions.Restrictions.UsageRestrictions.DailyTransactionCount,
        //         WeeklyTransactionCount: this.restrictions.Restrictions.UsageRestrictions.WeeklyTransactionCount,
        //         MonthlyTransactionCount: this.restrictions.Restrictions.UsageRestrictions.MonthlyTransactionCount,
        //         AnnualTransactionCount: this.restrictions.Restrictions.UsageRestrictions.AnnualTransactionCount,
        //         LifeTimeTransactionCount: this.restrictions.Restrictions.UsageRestrictions.LifeTimeTransactionCount

        if(this.dataToChange.DayTimeRestrictions.Monday === this.restrictions.Restrictions.DayTimeRestrictions.Monday 
            && this.dataToChange.DayTimeRestrictions.Tuesday === this.restrictions.Restrictions.DayTimeRestrictions.Tuesday 
            && this.dataToChange.DayTimeRestrictions.Wednesday === this.restrictions.Restrictions.DayTimeRestrictions.Wednesday 
            && this.dataToChange.DayTimeRestrictions.Thursday === this.restrictions.Restrictions.DayTimeRestrictions.Thursday 
            && this.dataToChange.DayTimeRestrictions.Friday === this.restrictions.Restrictions.DayTimeRestrictions.Friday 
            && this.dataToChange.DayTimeRestrictions.Saturday === this.restrictions.Restrictions.DayTimeRestrictions.Saturday 
            && this.dataToChange.DayTimeRestrictions.Sunday === this.restrictions.Restrictions.DayTimeRestrictions.Sunday 
            && this.dataToChange.DayTimeRestrictions.TimeFrom === this.restrictions.Restrictions.DayTimeRestrictions.TimeFrom 
            && this.dataToChange.DayTimeRestrictions.TimeTo === this.restrictions.Restrictions.DayTimeRestrictions.TimeTo
            && this.dataToChange.UsageRestrictions.DailySpend === this.restrictions.Restrictions.UsageRestrictions.DailySpend
            && this.dataToChange.UsageRestrictions.WeeklySpend === this.restrictions.Restrictions.UsageRestrictions.WeeklySpend
            && this.dataToChange.UsageRestrictions.MonthlySpend === this.restrictions.Restrictions.UsageRestrictions.MonthlySpend
            && this.dataToChange.UsageRestrictions.AnnualSpend === this.restrictions.Restrictions.UsageRestrictions.AnnualSpend
            && this.dataToChange.UsageRestrictions.LifeTimeSpend === this.restrictions.Restrictions.UsageRestrictions.LifeTimeSpend
            && this.dataToChange.UsageRestrictions.DailyTransactionCount === this.restrictions.Restrictions.UsageRestrictions.DailyTransactionCount
            && this.dataToChange.UsageRestrictions.WeeklyTransactionCount === this.restrictions.Restrictions.UsageRestrictions.WeeklyTransactionCount
            && this.dataToChange.UsageRestrictions.MonthlyTransactionCount === this.restrictions.Restrictions.UsageRestrictions.MonthlyTransactionCount
            && this.dataToChange.UsageRestrictions.AnnualTransactionCount === this.restrictions.Restrictions.UsageRestrictions.AnnualTransactionCount
            && this.dataToChange.UsageRestrictions.LifeTimeTransactionCount === this.restrictions.Restrictions.UsageRestrictions.LifeTimeTransactionCount
        ){
            this.errors.push("Aby zaktualizować restrykcje musisz coś zmienić.")
        }

        if(this.errors.length === 0){
            this.askForUpdateRestrictions = true
        }
    },
    checkWorkingTimeCard(){
        let workingTimeErrors = 0
        if(this.dataToChange.DayTimeRestrictions.TimeFrom !== null){
        let typeTimeFrom = typeof this.dataToChange.DayTimeRestrictions.TimeFrom
        if(typeTimeFrom === 'object' && this.dataToChange.DayTimeRestrictions.TimeFrom !== null){
            if(this.dataToChange.DayTimeRestrictions.TimeFrom.HH.length === 2 && this.dataToChange.DayTimeRestrictions.TimeFrom.mm.length === 2 && this.dataToChange.DayTimeRestrictions.TimeFrom.ss.length === 2){
                this.dataToChange.DayTimeRestrictions.TimeFrom = this.dataToChange.DayTimeRestrictions.TimeFrom.HH + ':' + this.dataToChange.DayTimeRestrictions.TimeFrom.mm + ':' + this.dataToChange.DayTimeRestrictions.TimeFrom.ss
            }else{
                workingTimeErrors++
                this.whereError.TimeFrom = true
                this.errors.push("Dostępność według godziny (dostępna od) - niepoprawne wartości")
            }
        }
        typeTimeFrom = typeof this.dataToChange.DayTimeRestrictions.TimeFrom
        if(typeTimeFrom !== 'object' && this.dataToChange.DayTimeRestrictions.TimeFrom !== null){
            if(this.dataToChange.DayTimeRestrictions.TimeFrom.length === 0 || (this.dataToChange.DayTimeRestrictions.TimeFrom[0] === "H" || this.dataToChange.DayTimeRestrictions.TimeFrom[1] === "H" || this.dataToChange.DayTimeRestrictions.TimeFrom[3] === "m" || this.dataToChange.DayTimeRestrictions.TimeFrom[4] === "m" || this.dataToChange.DayTimeRestrictions.TimeFrom[6] === "s" || this.dataToChange.DayTimeRestrictions.TimeFrom[7] === "s")){
                workingTimeErrors++
                this.whereError.TimeFrom = true
                this.errors.push("Dostępność według godziny (dostępna od) - niepoprawne wartości")
            }
        }
        }
        if(this.dataToChange.DayTimeRestrictions.TimeTo !== null){
            let typeTimeTo = typeof this.dataToChange.DayTimeRestrictions.TimeTo
            if(typeTimeTo === 'object' && this.dataToChange.DayTimeRestrictions.TimeTo !== null){
            if(this.dataToChange.DayTimeRestrictions.TimeTo.HH.length === 2 && this.dataToChange.DayTimeRestrictions.TimeTo.mm.length === 2 && this.dataToChange.DayTimeRestrictions.TimeTo.ss.length === 2){
                this.dataToChange.DayTimeRestrictions.TimeTo = this.dataToChange.DayTimeRestrictions.TimeTo.HH + ':' + this.dataToChange.DayTimeRestrictions.TimeTo.mm + ':' + this.dataToChange.DayTimeRestrictions.TimeTo.ss
            }else{
                workingTimeErrors++
                this.whereError.TimeTo = true
                this.errors.push("Dostępność według godziny (dostępna do) - niepoprawne wartości")
                }
            }
            typeTimeTo = typeof this.dataToChange.DayTimeRestrictions.TimeTo
            if(typeTimeTo !== 'object' && this.dataToChange.DayTimeRestrictions.TimeTo !== null){
                if(this.dataToChange.DayTimeRestrictions.TimeTo.length === 0 || (this.dataToChange.DayTimeRestrictions.TimeTo[0] === "H" || this.dataToChange.DayTimeRestrictions.TimeTo[1] === "H" || this.dataToChange.DayTimeRestrictions.TimeTo[3] === "m" || this.dataToChange.DayTimeRestrictions.TimeTo[4] === "m" || this.dataToChange.DayTimeRestrictions.TimeTo[6] === "s" || this.dataToChange.DayTimeRestrictions.TimeTo[7] === "s")){
                    workingTimeErrors++
                    this.whereError.TimeTo = true
                    this.errors.push("Dostępność według godziny (dostępna do) - niepoprawne wartości")
                }
            }
        }
        if((this.dataToChange.DayTimeRestrictions.TimeTo !== null && this.dataToChange.DayTimeRestrictions.TimeFrom === null) || (this.dataToChange.DayTimeRestrictions.TimeTo === null && this.dataToChange.DayTimeRestrictions.TimeFrom !== null)){
            workingTimeErrors++
            this.whereError.TimeTo = true
            this.whereError.TimeFrom = true
            this.errors.push("Dostępność według godziny - obie wartości muszą być wyłączone")
        }
        return workingTimeErrors
    },
    switchRestriction(type){
        if(type === 'Time'){
            if(this.dataToChange.DayTimeRestrictions.TimeTo !== null && this.dataToChange.DayTimeRestrictions.TimeFrom !== null ){
                this.dataToChange.DayTimeRestrictions.TimeFrom = null
                this.dataToChange.DayTimeRestrictions.TimeTo = null
            }else{
                this.dataToChange.DayTimeRestrictions.TimeFrom = "07:00:00",
                this.dataToChange.DayTimeRestrictions.TimeTo = "15:00:00"
            }
        }
        if(type === 'LifeTimeSpend'){
            if(this.dataToChange.UsageRestrictions.LifeTimeSpend !== null){
                this.dataToChange.UsageRestrictions.LifeTimeSpend = null
            }else{
                if(this.restrictions.Restrictions.UsageRestrictions.LifeTimeSpend !== null){
                    this.dataToChange.UsageRestrictions.LifeTimeSpend = this.restrictions.Restrictions.UsageRestrictions.LifeTimeSpend
                }else{
                    if(this.restrictions.Restrictions.UsageRestrictions.AnnualTransactionCount !== null){
                    this.dataToChange.UsageRestrictions.LifeTimeSpend = this.restrictions.Restrictions.UsageRestrictions.AnnualSpend
                    }else{
                    this.dataToChange.UsageRestrictions.LifeTimeSpend = 100
                    }
                }
            }
        }
        if(type === 'LifeTimeTransactionCount'){
            if(this.dataToChange.UsageRestrictions.LifeTimeTransactionCount !== null){
                this.dataToChange.UsageRestrictions.LifeTimeTransactionCount = null
            }else{
                if(this.restrictions.Restrictions.UsageRestrictions.LifeTimeTransactionCount !== null){
                    this.dataToChange.UsageRestrictions.LifeTimeTransactionCount = this.restrictions.Restrictions.UsageRestrictions.LifeTimeTransactionCount
                }else{
                    if(this.restrictions.Restrictions.UsageRestrictions.AnnualTransactionCount !== null){
                    this.dataToChange.UsageRestrictions.LifeTimeTransactionCount = this.restrictions.Restrictions.UsageRestrictions.AnnualTransactionCount
                    }else{
                    this.dataToChange.UsageRestrictions.LifeTimeTransactionCount = 100
                    }
                }
            }
        }
    },
    checkValue(type){
        if(type === "DailySpend"){
            if(this.dataToChange.UsageRestrictions.DailySpend.length !== 0){
                if(this.dataToChange.UsageRestrictions.DailySpend < 1){
                    this.dataToChange.UsageRestrictions.DailySpend = 1
                }
            }
        }
        if(type === "WeeklySpend"){
            if(this.dataToChange.UsageRestrictions.WeeklySpend.length !== 0){
                if(this.dataToChange.UsageRestrictions.WeeklySpend < 1){
                    this.dataToChange.UsageRestrictions.WeeklySpend = 1
                }
            }
        }
        if(type === "MonthlySpend"){
            if(this.dataToChange.UsageRestrictions.MonthlySpend.length !== 0){
                if(this.dataToChange.UsageRestrictions.MonthlySpend < 1){
                    this.dataToChange.UsageRestrictions.MonthlySpend = 1
                }
            }
        }
        if(type === "AnnualSpend"){
            if(this.dataToChange.UsageRestrictions.AnnualSpend.length !== 0){
                if(this.dataToChange.UsageRestrictions.AnnualSpend < 1){
                    this.dataToChange.UsageRestrictions.AnnualSpend = 1
                }
            }
        }
        if(type === "LifeTimeSpend"){
            if(this.dataToChange.UsageRestrictions.LifeTimeSpend.length !== 0){
                if(this.dataToChange.UsageRestrictions.LifeTimeSpend < 1){
                    this.dataToChange.UsageRestrictions.LifeTimeSpend = 1
                }
            }
        }
        if(type === "DailyTransactionCount"){
            if(this.dataToChange.UsageRestrictions.DailyTransactionCount.length !== 0){
                if(this.dataToChange.UsageRestrictions.DailyTransactionCount < 1){
                    this.dataToChange.UsageRestrictions.DailyTransactionCount = 1
                }
            }
        }
        if(type === "WeeklyTransactionCount"){
            if(this.dataToChange.UsageRestrictions.WeeklyTransactionCount.length !== 0){
                if(this.dataToChange.UsageRestrictions.WeeklyTransactionCount < 1){
                    this.dataToChange.UsageRestrictions.WeeklyTransactionCount = 1
                }
            }
        }
        if(type === "MonthlyTransactionCount"){
            if(this.dataToChange.UsageRestrictions.MonthlyTransactionCount.length !== 0){
                if(this.dataToChange.UsageRestrictions.MonthlyTransactionCount < 1){
                    this.dataToChange.UsageRestrictions.MonthlyTransactionCount = 1
                }
            }
        }
        if(type === "AnnualTransactionCount"){
            if(this.dataToChange.UsageRestrictions.AnnualTransactionCount.length !== 0){
                if(this.dataToChange.UsageRestrictions.AnnualTransactionCount < 1){
                    this.dataToChange.UsageRestrictions.AnnualTransactionCount = 1
                }
            }
        }
        if(type === "LifeTimeTransactionCount"){
            if(this.dataToChange.UsageRestrictions.LifeTimeTransactionCount.length !== 0){
                if(this.dataToChange.UsageRestrictions.LifeTimeTransactionCount < 1){
                    this.dataToChange.UsageRestrictions.LifeTimeTransactionCount = 1
                }
            }
        }
    },
    closeModal()
    {
        this.$emit("closeModal");
    },
    overflowTimepicker(){
        if(this.editTime){
            setTimeout(() => {
                this.timePickerOverflow = false
            }, 50);
        }
        if(!this.editTime){
            this.timePickerOverflow = true
        }
    },
    switchDay(day){
        if(day === "Monday"){
            this.dataToChange.DayTimeRestrictions.Monday = !this.dataToChange.DayTimeRestrictions.Monday
        }
        if(day === "Tuesday"){
            this.dataToChange.DayTimeRestrictions.Tuesday = !this.dataToChange.DayTimeRestrictions.Tuesday
        }
        if(day === "Wednesday"){
            this.dataToChange.DayTimeRestrictions.Wednesday = !this.dataToChange.DayTimeRestrictions.Wednesday
        }
        if(day === "Thursday"){
            this.dataToChange.DayTimeRestrictions.Thursday = !this.dataToChange.DayTimeRestrictions.Thursday
        }
        if(day === "Friday"){
            this.dataToChange.DayTimeRestrictions.Friday = !this.dataToChange.DayTimeRestrictions.Friday
        }
        if(day === "Saturday"){
            this.dataToChange.DayTimeRestrictions.Saturday = !this.dataToChange.DayTimeRestrictions.Saturday
        }
        if(day === "Sunday"){
            this.dataToChange.DayTimeRestrictions.Sunday = !this.dataToChange.DayTimeRestrictions.Sunday
        }
    },
    copyToChangeData(){
        // restrictions.Restrictions.DayTimeRestrictions.Monday
        if(this.restrictions.Restrictions.DayTimeRestrictions !== null){
            this.dataToChange.DayTimeRestrictions = {
                Monday: this.restrictions.Restrictions.DayTimeRestrictions.Monday,
                Tuesday: this.restrictions.Restrictions.DayTimeRestrictions.Tuesday,
                Wednesday: this.restrictions.Restrictions.DayTimeRestrictions.Wednesday,
                Thursday: this.restrictions.Restrictions.DayTimeRestrictions.Thursday,
                Friday: this.restrictions.Restrictions.DayTimeRestrictions.Friday,
                Saturday: this.restrictions.Restrictions.DayTimeRestrictions.Saturday,
                Sunday: this.restrictions.Restrictions.DayTimeRestrictions.Sunday,
                TimeFrom: this.restrictions.Restrictions.DayTimeRestrictions.TimeFrom,
                TimeTo: this.restrictions.Restrictions.DayTimeRestrictions.TimeTo
            }
        }
        if(this.restrictions.Restrictions.UsageRestrictions !== null){
            this.dataToChange.UsageRestrictions = {
                DailySpend: this.restrictions.Restrictions.UsageRestrictions.DailySpend,
                WeeklySpend: this.restrictions.Restrictions.UsageRestrictions.WeeklySpend,
                MonthlySpend: this.restrictions.Restrictions.UsageRestrictions.MonthlySpend,
                AnnualSpend: this.restrictions.Restrictions.UsageRestrictions.AnnualSpend,
                LifeTimeSpend: this.restrictions.Restrictions.UsageRestrictions.LifeTimeSpend,
                DailyTransactionCount: this.restrictions.Restrictions.UsageRestrictions.DailyTransactionCount,
                WeeklyTransactionCount: this.restrictions.Restrictions.UsageRestrictions.WeeklyTransactionCount,
                MonthlyTransactionCount: this.restrictions.Restrictions.UsageRestrictions.MonthlyTransactionCount,
                AnnualTransactionCount: this.restrictions.Restrictions.UsageRestrictions.AnnualTransactionCount,
                LifeTimeTransactionCount: this.restrictions.Restrictions.UsageRestrictions.LifeTimeTransactionCount
            }
        }
    },
    async getRestrictions(){
        this.loadingRestrictions = true
        this.restrictions = null
        const res = await axios.post("https://europe-central2-lauron-a8b59.cloudfunctions.net/api/getRestrictions", {
            cardId: `${this.$route.query.cid}`
        })
        if(res.data.success){
            this.restrictions = res.data.restrictions
            this.copyToChangeData()
            this.loadingRestrictions = false
        }else{
            this.restrictions = null
            this.loadingRestrictions = false
        }
    }
},
computed:
{

},
  mounted() {
  },
  created(){
    this.getRestrictions()
  },
  setup() {
    const open = ref(true)
    return {
      open,
    }
  },
}
</script>
<style lang="scss" scoped>
    .fade-leave-active {
    transition: opacity 0.3s ease;
    }
    .fade-enter-active {
    transition: opacity 1s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    }
</style>