<template>
  <div>
    <p class="mb-4 truncate text-sm text-slate-200">Dostępne środki, oraz szczegółowe dane transakcji.</p>
  <div>
        <div>
            <div>
                <h3 class="text-lg font-medium leading-6 text-slate-300">Ostatnia faktura</h3>
                <dl class="mt-3 grid grid-cols-1 gap-5 xl:grid-cols-3">
                <div class="overflow-hidden rounded-lg bg-slate-100 px-4 py-5 shadow ">
                    <dt class="truncate text-xs font-medium text-slate-500">Kwota faktury</dt>
                    <dd v-if="!dataLoading" class="mt-1 text-xl font-semibold tracking-tight text-slate-900">{{this.lastInvoice.valueOfInvoice}}</dd>
                    <dd v-if="dataLoading" class="mt-1 text-xl font-semibold tracking-tight pt-3 pb-1 text-slate-900"><LoaderData></LoaderData></dd>
                </div>
                <div class="overflow-hidden rounded-lg bg-slate-100 px-4 py-5 shadow ">
                    <dt class="truncate text-xs font-medium text-slate-500">Pozostało do zapłaty</dt>
                    <dd v-if="!dataLoading" class="mt-1 text-xl font-semibold tracking-tight text-slate-900">{{this.lastInvoice.amountToPay}}</dd>
                    <dd v-if="dataLoading" class="mt-1 text-xl font-semibold tracking-tight pt-3 pb-1 text-slate-900"><LoaderData></LoaderData></dd>
                </div>
                <div class="overflow-hidden rounded-lg bg-slate-100 px-4 py-5 shadow ">
                    <dt class="truncate text-xs font-medium text-slate-500">Numer faktury</dt>
                    <dd v-if="!dataLoading" class="mt-1 text-xl font-semibold tracking-tight text-slate-900">{{this.lastInvoice.invoiceName}}</dd>
                    <dd v-if="dataLoading" class="mt-1 text-xl font-semibold tracking-tight pt-3 pb-1 text-slate-900"><LoaderData></LoaderData></dd>
                </div>
                </dl>
                <h3 class="mt-5 text-lg font-medium leading-6 text-slate-300">Wszystkie wystawione faktury</h3>
                <dl class="mt-3 grid grid-cols-1 gap-5 xl:grid-cols-2">
                <div class="overflow-hidden rounded-lg bg-slate-100 px-4 py-5 shadow ">
                    <dt class="truncate text-xs font-medium text-slate-500">Łączna kwota faktur</dt>
                    <dd v-if="!dataLoading" class="mt-1 text-xl font-semibold tracking-tight text-slate-900">{{this.allInvoices.valueOfInvoices}}</dd>
                    <dd v-if="dataLoading" class="mt-1 text-xl font-semibold tracking-tight pt-3 pb-1 text-slate-900"><LoaderData></LoaderData></dd>
                </div>
                <div class="overflow-hidden rounded-lg bg-slate-100 px-4 py-5 shadow ">
                    <dt class="truncate text-xs font-medium text-slate-500">Pozostało do zapłaty</dt>
                    <dd v-if="!dataLoading" class="mt-1 text-xl font-semibold tracking-tight text-slate-900">{{this.allInvoices.amountToPay}}</dd>
                    <dd v-if="dataLoading" class="mt-1 text-xl font-semibold tracking-tight pt-3 pb-1 text-slate-900"><LoaderData></LoaderData></dd>
                </div>
                </dl>
                <h3 class="mt-5 text-lg font-medium leading-6 text-slate-300">Zaległości</h3>
                <dl class="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2">
                <div class="overflow-hidden rounded-lg bg-slate-100 px-4 py-5 shadow ">
                    <dt class="truncate text-xs font-medium text-slate-500">Pozostało do zapłaty (łącznie)</dt>
                    <dd v-if="!dataLoading" class="mt-1 text-xl font-semibold tracking-tight text-slate-900">{{this.obligations.amountToPay}}</dd>
                    <dd v-if="dataLoading" class="mt-1 text-xl font-semibold tracking-tight pt-3 pb-1 text-slate-900"><LoaderData></LoaderData></dd>
                </div>
                <div class="overflow-hidden rounded-lg bg-slate-100 px-4 py-5 shadow ">
                    <dt class="truncate text-xs font-medium text-slate-500">Faktura do opłacenia (pierwsza w kolejności)</dt>
                    <dd v-if="!dataLoading" class="mt-1 text-xl font-semibold tracking-tight text-slate-900">{{this.obligations.invoiceName}}</dd>
                    <dd v-if="dataLoading" class="mt-1 text-xl font-semibold tracking-tight pt-3 pb-1 text-slate-900"><LoaderData></LoaderData></dd>
                </div>
                </dl>
            </div>
        </div>
        <div class="mt-4">
            <div class="py-2">
                <h3 class="text-lg font-medium leading-6 text-slate-50">Ostatnie transakcje</h3>
                <p class="mt-1 max-w-2xl text-sm text-slate-100">Transakcje wykonane w ciągu ostatnich 48 godzin.</p>
            </div>
        </div>
        <div class="mt-2 flex flex-col">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle px-4 md:px-6 lg:px-8">
                <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
                    <table class="min-w-full divide-y divide-slate-900">
                    <thead class="bg-slate-800">
                        <tr>
                        <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-slate-300 sm:pl-6">ID transakcji</th>
                        <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-300">Stacja</th>
                        <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-300">Nr rejestracyjny</th>
                        <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-300">Karta</th>
                        <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-300">Produkt</th>
                        <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-300">Ilość</th>
                        <!-- <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-300">Kwota brutto</th> -->
                        <!-- <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-300">Kwota netto</th> -->
                        <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-300">Data transakcji</th>
                        </tr>
                    </thead>
                    <tbody v-if="!transactionsLoading && transactions !== null" class="divide-y divide-slate-700 bg-slate-500">
                        <tr v-for="(transaction, index) in transactions.slice(0, hitsOnPage)" :key="index" :class="[index % 2 ? 'bg-slate-700' : 'bg-slate-600']">
                        <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-slate-300 sm:pl-6"> {{transaction.TransactionId.length > 0 ? transaction.TransactionId : '--'}} </td>
                        <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-slate-100">
                        <!-- {{transaction.NetworkDelcoName.length > 0 ? transaction.NetworkDelcoName : ''}}{{transaction.NetworkDelcoName.length > 0 && transaction.SiteName.length > 0 ? ' - ' : ''}}{{transaction.SiteName.length > 0 ? transaction.SiteName : ''}}{{transaction.NetworkDelcoName.length < 1 && transaction.SiteName.length < 1 ? '--' : ''}} -->
                        <span v-tooltip="`${transaction.NetworkDelcoName.length > 0 ? transaction.NetworkDelcoName : ''}${transaction.NetworkDelcoName.length > 0 && transaction.SiteName.length > 0 ? ' - ' : ''}${transaction.SiteName.length > 0 ? transaction.SiteName : ''}${transaction.NetworkDelcoName.length < 1 && transaction.SiteName.length < 1 ? '--' : ''}`">
                            {{(transaction.NetworkDelcoName.length > 0 && transaction.SiteName.length > 0 ? transaction.NetworkDelcoName + ' - ' + transaction.SiteName : transaction.NetworkDelcoName.length > 0 ? transaction.NetworkDelcoName : transaction.SiteName.length > 0 ? transaction.SiteName : '--').length <= 35 ? transaction.NetworkDelcoName.length > 0 && transaction.SiteName.length > 0 ? transaction.NetworkDelcoName + ' - ' + transaction.SiteName : transaction.NetworkDelcoName.length > 0 ? transaction.NetworkDelcoName : transaction.SiteName.length > 0 ? transaction.SiteName : '--' : (transaction.NetworkDelcoName.length > 0 && transaction.SiteName.length > 0 ? transaction.NetworkDelcoName + ' - ' + transaction.SiteName : transaction.NetworkDelcoName.length > 0 ? transaction.NetworkDelcoName : transaction.SiteName.length > 0 ? transaction.SiteName : '--').substring(0,35) + '...'}}
                        </span>
                        <a v-if="transaction.Latitude.length > 0 && transaction.Longitude.length > 0" :href="`https://www.google.pl/maps/@${transaction.Latitude},${transaction.Longitude},16z`" target="_blank" class="transition ease-in-out duration-300 text-xxs ml-2 font-normal text-blue-400 hover:text-blue-500">Pokaż na mapie</a>
                        </td>
                        <td class="whitespace-nowrap px-2 py-2 text-sm text-slate-100"> {{transaction.VehicleRegistrationNumber.length > 0 ? transaction.VehicleRegistrationNumber : '--'}} </td>
                        <td class="whitespace-nowrap px-2 py-2 text-sm text-slate-100"> {{transaction.CardPAN.length > 0 ? transaction.CardPAN : '--'}} </td>
                        <td class="whitespace-nowrap px-2 py-2 text-sm text-slate-100" v-tooltip="`${transaction.ProductName.length > 0 ? transaction.ProductName : '--'}`"> {{transaction.ProductName.length > 0 ? transaction.ProductName.length < 15 ? transaction.ProductName : transaction.ProductName.substring(0,15) + '...' : '--'}} </td>
                        <td class="whitespace-nowrap px-2 py-2 text-sm text-slate-300"> {{transaction.FuelVolume !== undefined && transaction.FuelVolume !== null && transaction.FuelVolume > 0 ? transaction.FuelVolume : '--'}}{{transaction.UnitOfMeasure !== undefined && transaction.UnitOfMeasure !== null ? transaction.UnitOfMeasure : ''}}</td>
                        <!-- <td class="whitespace-nowrap px-2 py-2 text-sm text-slate-300"> {{transaction.Amount > 0 ? transaction.Amount.toFixed(2) : '--'}} {{transaction.TransactionCurrencySymbol}}</td> -->
                        <!-- <td class="whitespace-nowrap px-2 py-2 text-sm text-slate-100"> {{transaction.Amount > 0 ? transaction.Amount.toFixed(2) : '--'}} {{transaction.TransactionCurrencySymbol}}</td> -->
                        <td class="whitespace-nowrap px-2 py-2 text-sm text-slate-300"> {{translateDate(transaction.TransactionDate + ' ' + transaction.TransactionTime, 'dateWithHour')}} </td>
                        </tr>
                    </tbody>
                    </table>
                    <EmptyState v-if="!transactionsLoading && transactions === null || !transactionsLoading && transactions.length === 0"></EmptyState>
                </div>
                </div>
                <div v-if="transactionsLoading">
                        <LoadingSpinnerHub></LoadingSpinnerHub>
                </div>
            </div>
                <div v-if="!transactionsLoading && transactions !== null && transactions.length > hitsOnPage" class="w-full flex justify-center p-0.5 mt-2">
                        <span @click.prevent="addHits()" class="transition duration-300 ease-in-out px-2 py-1 text-sm shadow-lg bg-slate-200 hover:bg-slate-300 rounded-md cursor-pointer">Wczytaj więcej</span>
                </div>
            </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import LoadingSpinnerHub from '../components/LoadingSpinnerHub.vue';
import LoaderData from '../components/LoaderData.vue';
import EmptyState from '../components/EmptyState.vue';
import axios from 'axios';
import { db } from "../firebase/gfbconf"

export default {
    data()
        {
            return {
                dataLoading: true,
                transactionsLoading: false,
                billings: null,
                transactions: null,
                hitsOnPage: 10,
                lastInvoice: null,
                allInvoices: null,
                obligations: null
            }
        },
    components: {
        LoadingSpinnerHub,
        LoaderData,
        EmptyState
    },
    methods: {
        translateDate(date, type){
            moment.locale('pl');
            let translatedDate = ""
            if(type === 'dateWithHour'){
                translatedDate = moment(date, "YYYY-MM-DD h:mm:ss").format('LLL')
            }
            return translatedDate
        },
        addHits(){
            if(this.transactions.length - 10 < this.hitsOnPage){
                this.hitsOnPage = this.transactions.length
            }else{
                this.hitsOnPage += 10
            }
        },
        sortTransactions()
            {
              this.transactions.sort((a,b)=>
              {
                if(a.TransactionDateUnix > b.TransactionDateUnix) return 1;
                if(a.TransactionDateUnix < b.TransactionDateUnix) return -1;
                return 0;
              })
              this.transactions.reverse();
            },
        async downloadTransactions(){
            // alert('not ready')
            this.transactionsLoading = true
            this.transactions = null
            const res = await axios.post("https://europe-central2-lauron-a8b59.cloudfunctions.net/api/getTransactionsFeed", {
                group: this.$store.state.userData.groupOfCards
            })
            if(res.data.success)
            {
                if(res.data.transactions !== null){
                this.transactions = res.data.transactions

                if(this.transactions.length > 0){
                    this.transactions.forEach(transaction => {
                        transaction.TransactionDateUnix = moment(transaction.TransactionDate + ' ' + transaction.TransactionTime, "YYYYMMDD h:mm:ss").unix()
                        if(transaction.NetworkDelcoName === 'Shell Polska sp. z o.o.'){
                            transaction.NetworkDelcoName = 'Shell Polska'
                        }
                        transaction.Amount = parseFloat(transaction.Amount)
                    });
                    this.sortTransactions();
                }
                }else{
                    this.transactions = null
                }
                this.transactionsLoading = false
            }else{
                this.transactionsLoading = false
                alert('Nie udało się pobrać transakcji')
            }
        },
        async downloadBillings(){
            this.dataLoading = true
            this.billings = null
            const res = await axios.post("https://europe-central2-lauron-a8b59.cloudfunctions.net/api/getBillings", {
              payer: "PL60043902"
            })
            if(res.data.success)
            {
                if(res.data.cards !== null){
                this.billings = res.data.billings
                }else{
                    this.billings = null
                }
                this.dataLoading = false
            }else{
                this.dataLoading = false
                alert('Coś poszło nie tak')
            }
        },
        async downloadInvoices(){
            this.dataLoading = true
            let invoicesRes = await db.collection("Invoices").where("receiver", "==", this.$store.state.user.uid).where("active", "==", true).orderBy("created.unix", "desc").get()
            let invoices = []

            let allInvoicesValueOfInvoices = 0
            let allInvoicesAmountToPay = 0
            let lastToPay = "-"
            
            if(invoicesRes.docs.length > 0){
                invoicesRes.docs.forEach(invoice => {
                    let currentInvoice = invoice.data()
                    invoices.push(currentInvoice)
                    allInvoicesValueOfInvoices += currentInvoice.valueOfInvoice
                    allInvoicesAmountToPay += (currentInvoice.valueOfInvoice - currentInvoice.paidAmount)
                    if(currentInvoice.valueOfInvoice - currentInvoice.paidAmount > 0){
                        lastToPay = currentInvoice.invoiceName
                    }
                });
                this.lastInvoice = {
                    valueOfInvoice: invoices[0].valueOfInvoice.toFixed(2) + ' PLN',
                    amountToPay: (invoices[0].valueOfInvoice - invoices[0].paidAmount).toFixed(2) + ' PLN',
                    invoiceName: invoices[0].invoiceName
                },
                this.allInvoices = {
                    valueOfInvoices: allInvoicesValueOfInvoices.toFixed(2) + ' PLN',
                    amountToPay: allInvoicesAmountToPay.toFixed(2) + ' PLN',
                },
                this.obligations = {
                    amountToPay: allInvoicesAmountToPay.toFixed(2) + ' PLN',
                    invoiceName: lastToPay
                }
            }else{
                this.lastInvoice = {
                    valueOfInvoice: "-",
                    amountToPay: "-",
                    invoiceName: "-"
                },
                this.allInvoices = {
                    valueOfInvoices: "-",
                    amountToPay: "-",
                },
                this.obligations = {
                    amountToPay: "-",
                    invoiceName: "-"
                }
            }
            this.dataLoading = false
        }
    },
    created(){
        // this.downloadBillings()
        this.downloadInvoices()
        this.downloadTransactions()
    }
}
</script>

<style scoped>

</style>